import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class OrganizationResource extends AuthenticatedResource {
  static urlRoot = '/organizations' as const;

  readonly id: number | undefined = undefined;
  readonly title: string = '';
  readonly courses: Record<string, unknown>[] = [];
  readonly managers: Record<string, unknown>[] = [];
  readonly logo_url: string = '';
  readonly hasPaymentProviders: boolean = false;
  readonly created_at: Date = new Date(0);
  readonly updated_at: Date = new Date(0);

  pk() {
    return this.id?.toString();
  }

}
