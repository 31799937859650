import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CollectionResource from 'resources/organization/CourseResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import { collectionsColumns } from 'utils/columns/collectionsColumns';
import { useController } from '@rest-hooks/react';

const CollectionsTabContent = ({handleError, organizationId}) => {
  const { fetch } = useController();

  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collections = await fetch(CollectionResource.list(), {organization_id: organizationId})
        setCollections(collections);
      } catch (error) {
        handleError(error.message);
      }    
    };

    fetchData();
  }, []);

  return (
    <div style={{ flexGrow: 1 }}>
      <DataSelectionTable records={collections}
                          columns={collectionsColumns}
      />
  </div>
)};

CollectionsTabContent.propTypes = {
  handleError: PropTypes.func,
  organizationId: PropTypes.number
};

export default CollectionsTabContent;
