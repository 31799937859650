import React from 'react';
import PropTypes from 'prop-types';

import PostCard from 'components/Cards/PostCard/PostCard';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

const PostPreviewModal = ({open, onClose, selectedPost}) => {
    
    return (

    <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={ onClose }>
      <DialogContent>
        <PostCard selectedPost={selectedPost} />
      </DialogContent>
    </Dialog>
  );
};

PostPreviewModal.propTypes = {
    open: PropTypes.true,
    onClose: PropTypes.func,
    handleError: PropTypes.func,
    selectedPost: PropTypes.object,
    organizationId: PropTypes.number
};

export default PostPreviewModal;