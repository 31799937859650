import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import OrganizationOfferInstanceResource from 'resources/organization/OfferInstanceResource';
import OfferInstanceDownloadResource from 'resources/organization/OfferInstanceDownloadResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import { downloadCSV } from 'utils/downloadFile';
import { offerInstancesColumns } from 'utils/columns/offerInstancesColumns';

import WithPermission from 'components/WithPermission';
import OffersFallback from 'components/fallbacks/Offers';

const OfferInstancesTabContent = ({handleError, organizationId}) => {

  const { fetch } = useController();

  const [offerInstances, setOfferInstances] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const offerInstances = await fetch(OrganizationOfferInstanceResource.list(), {organization_id: organizationId});
        setOfferInstances(offerInstances);
      } catch (error) {
        handleError(error.message);
      }
    };

    fetchData();
  }, [])


  const handleDownload = async () => {
    try {
      const response = await fetch(OfferInstanceDownloadResource.list(), {organization_id: organizationId});
      downloadCSV(response, 'redeemed_offers.csv', 'text/csv');
    } catch (error) {
      handleError(error.message);
    }
  };

  return (
    <WithPermission permission={'hasOffers'} fallback={<OffersFallback />}>

      <div style={{ flexGrow: 1 }}>
        <DataSelectionTable records={offerInstances}
                          columns={offerInstancesColumns}
                          checkboxSelectRows={false}
                          onClick={() => handleDownload("offerInstances")}
        />
      </div>
      
    </WithPermission> 
)};

OfferInstancesTabContent.propTypes = {
    handleError: PropTypes.func,
    organizationId: PropTypes.number,
  };

export default OfferInstancesTabContent;
