import React, { useState } from 'react'
import PostResource from 'resources/organization/PostResource';
import { useController, useSuspense } from '@rest-hooks/react';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { List, ListItem, ListItemText } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import formattedDate from 'utils/formattedDate';
import PreviewIcon from '@mui/icons-material/Preview';
import { useHistory, useParams } from 'react-router-dom';
import PostPreviewModal from './PostPreviewModal';
import StatusModal from 'components/StatusModal';

const PostsList = () => {
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [preview, setPreview] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);

    const { fetch } = useController();
    const history = useHistory();
    const params  = useParams();

    const organizationId = params.organizationId;

    const data = useSuspense(PostResource.list(), {organization_id: organizationId});
    const { setOpen, setMessage, setSeverity } = useToast();


    const handlePreview = (post) => {
      setSelectedPost(post);
      setPreview(true);
    };

    const handlePreviewClose = () => {
      setPreview(false);
      setSelectedPost(null);
    };

    const handleError = async (error) => {
        if (error.response) {
            const { errors } = await error.response.json();
            const errorMessage = errors ? errors.base[0] : error.message;
            setError(errorMessage);
          } else {
            setError(error.message);
          }
          setOpenErrorModal(true);
    };


    const handleDelete = async () => {
        try {
            await fetch(PostResource.delete(), {organization_id: organizationId, id: selectedPost.id});
            await fetch(PostResource.list(), {organization_id: organizationId});
            customToast('success', `Post Deleted`, setOpen, setSeverity, setMessage);
            setSelectedPost(null);
        } catch (error) {
            handleError(error)
        }
    };

    const handleConfirmDeleteModal = (post) => {
        setSelectedPost(post)
        setConfirmDelete(true)
    };

  return (
    <div>
        <List>
                <ListItem 
                    sx={{backgroundColor: "#ffff", marginTop: '2px'}}>
                    <ListItemText primary={`Add new content`} />
                    <IconButton onClick={() => history.push(`/organizations/${organizationId}/content/new`)}>
                        <AddIcon color="warning" />
                    </IconButton>
                </ListItem>
            {data.map((post, index) => (
                <ListItem 
                    key={index + 1}
                    sx={{backgroundColor: "#ffff", marginTop: '2px'}}>

                    <ListItemText primary={`Post #${post.id}, created at: ${formattedDate(post.created_at)}`} />
                    <IconButton onClick={() =>  handlePreview(post)}>
                        <PreviewIcon color="warning" />
                    </IconButton>
                    <IconButton onClick={() => history.push(`/organizations/${organizationId}/content/${post.id}/edit`)}>
                        <EditIcon color="warning" />
                    </IconButton>
                    <IconButton onClick={() => handleConfirmDeleteModal(post)}>
                        <DeleteIcon color="error" />
                    </IconButton>

                </ListItem>
            ))}
        </List>

        <ConfirmDialog
                title={`Deleting post`}
                open={ confirmDelete }
                setOpen={setConfirmDelete}
                onConfirm={ () => handleDelete() }
            >
                You are about to delete a post. Would you like to continue?
        </ConfirmDialog>

        { preview && <PostPreviewModal open={preview} onClose={handlePreviewClose} selectedPost={selectedPost} />}


        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ () => setOpenErrorModal(false) } /> }

    </div>
  )
};

export default PostsList;