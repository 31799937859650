import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const Title = ({reward, isDisabled, onChange}) => {

  return <>
    <div className={`new-form-field`}>
      <TextField
        autoFocus
        fullWidth
        name="title"
        label={`Title *`}
        color="warning"
        value={reward.title}
        onChange={onChange}
        disabled={isDisabled} />
    </div>
    <div className={`new-form-field`}>
      <TextField
        fullWidth
        multiline
        minRows={3}
        name="description"
        label={`Pass Description *`}
        color="warning"
        value={reward.description}
        onChange={onChange}
        disabled={isDisabled} />
    </div>
  </>;
}

Title.propTypes = {
  reward: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Title;


