import React from 'react';
import PropTypes from 'prop-types';
import { claimState } from 'utils/constants';
import Chip from '@mui/material/Chip';
import { t } from 'i18n/index';

const ClaimStatus = ({id, claim_state, external_url, onClaim}) => {
  const claimed = claim_state !== claimState.UNCLAIMED.value;

  return <div style={{ textAlign: "center" }}>
    {claimed ?
      <Chip
        label={claimState[claim_state.toUpperCase()].label}
        color="success"
        onClick={() => window.open(external_url, "_blank")} />
      :
      <Chip
        label={t('studentProfile:collectorsPortal_ClaimReward')}
        color="warning"
        onClick={() => onClaim(id)} />}
  </div>;
};

ClaimStatus.propTypes = {
  id: PropTypes.number.isRequired,
  claim_state: PropTypes.string.isRequired,
  external_url: PropTypes.string.isRequired,
  onClaim: PropTypes.func.isRequired,
};

export default ClaimStatus;
