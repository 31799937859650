import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import RewardInstanceDownloadResource from 'resources/organization/RewardInstanceDownloadResource';
import RewardInstanceResource from 'resources/organization/RewardInstanceResource';
import RewardResource from 'resources/organization/RewardResource';
import CollectionResource from 'resources/organization/CourseResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import { downloadCSV } from 'utils/downloadFile';
import Stats from '../CollectionsTabContent/Stats';
import { useController } from '@rest-hooks/react';
import { rewardInstancesColumns } from 'utils/columns/rewardInstancesColumns';
import { useHistory } from 'react-router-dom';


const RewardInstancesTabContent = ({handleError, organizationId}) => {
    const { fetch } = useController();
    const history = useHistory();

    const [collectionId, setCollectionId] = useState((new URL(document.location)).searchParams.get("collection"));
    const [rewardId, setRewardId] = useState((new URL(document.location)).searchParams.get("collectible"));
    
    const [rewards, setRewards] = useState([]);
    const [rewardInstances, setRewardInstances] = useState([]);
    const [collections, setCollections] = useState([]);

    const [query, setQuery] = useState("");
    const [filteredRewardInstances, setFilteredRewardInstances] = useState([])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const rewards = await fetch(RewardResource.list(), {organization_id: organizationId});
            setRewards(rewards);
    
            const rewardInstances = await fetch(RewardInstanceResource.list(), {organization_id: organizationId, course_id: collectionId, reward_id: rewardId});
            setRewardInstances(rewardInstances);

            const collections = await fetch(CollectionResource.list(), {organization_id: organizationId})
            setCollections(collections);

          } catch (error) {
            handleError(error.message);
          }    
        };
    
        fetchData();
      }, []);

      useEffect(() => {
        const result = rewardInstances.filter(({first_name, last_name, tx, claimed}) => {
            last_name = last_name || ''
            tx = tx || ''
            claimed = claimed || '' ? 'minted' : 'not claimed';
    
            const lcQuery = query.toLowerCase();   
    
            return (first_name.toLowerCase().includes(lcQuery) ||
                    last_name.toLowerCase().includes(lcQuery)  ||
                    tx.toLowerCase().includes(lcQuery) ||
                    claimed.toLowerCase().includes(lcQuery));
        });
    
        setFilteredRewardInstances(result);
      }, [query]);

    const handleDownload = async () => {
        try {
            const response = await fetch(RewardInstanceDownloadResource.list(), {organization_id: organizationId, course_id: collectionId, reward_id: rewardId });
            downloadCSV(response, 'claimed_nfts.csv', 'text/csv');
        } catch (error) {
            handleError(error.message);
        }
    };

    const handleFilter = async (collectionId, rewardId) => {

        const rewardInstances = await fetch(RewardInstanceResource.list(), {organization_id: organizationId, course_id: collectionId, reward_id: rewardId});
        setRewardInstances(rewardInstances);
    
        const searchParams = {};
        if (collectionId) {
          searchParams.collection = collectionId;
        }
        if (rewardId) {
          searchParams.collectible = rewardId;
        }
    
        const search = new URLSearchParams(searchParams).toString();
    
        history.push({
          search: `?${search}`
        })
      
        setCollectionId(collectionId);
        setRewardId(rewardId);
      };
    

  return (
    <>
        <Stats rewardInstances={ query.length > 1 ? filteredRewardInstances : rewardInstances } />
        <div style={{ flexGrow: 1 }}>
        <DataSelectionTable records={query.length > 1 ? filteredRewardInstances : rewardInstances}
                            columns={rewardInstancesColumns}
                            collections={collections}
                            rewards={rewards}
                            onQueryChange={setQuery}
                            queryString={query}
                            handleFilter={handleFilter}
                            collectionId={collectionId}
                            rewardId={rewardId}
                            onClick={handleDownload}
                            />
        </div>
    </>
)};

RewardInstancesTabContent.propTypes = {
    handleError: PropTypes.func,
    organizationId: PropTypes.number
 };

export default RewardInstancesTabContent;
