import React from 'react';
import PropTypes from 'prop-types';

import { rewardType, courseType } from 'utils/constants';
import { capitalize } from 'utils/capitalize';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Tooltip } from '@mui/material';
import { emptyProperty } from './consts';

export const Metadata = ({reward, course, isDisabled, onChange}) => {
  if (course.category === courseType.MEMBERSHIP.value) {
    return null;
  }

  const properties = [ ...reward.metadata ];

  // append empty property if the last one is not empty
  if (properties.length === 0 || properties[properties.length - 1].key !== "") {
    properties.push({...emptyProperty });
  }


  const handlePropertyChange = (event, index) => {
    let data = [...properties];
    data[index][event.target.name] = event.target.value;
    onChange({target: {name: "metadata", value: data }});
  };

  const addProperty = (index) => {
    let data = [... properties];
    data.splice(index+1, 0, {...emptyProperty });
    onChange({target: {name: "metadata", value: data }});
  };

  const removeProperty = (index) => {
    let data = [...properties];
    data.splice(index, 1);
    onChange({target: {name: "metadata", value: data }});
  };

  return <>
    <h3 className="metadata-title">{capitalize(rewardType[reward.category.toUpperCase()].label)} Attributes</h3>
    <p className='metadata-description'>Create custom attributes uniquely for this NFT. These will then be shown as unique Metadata on your NFT. You can have multiple attributes with the same name and different values.</p>

    {properties.map((property, index) => {
      return (
        <div className="property-row" key={`property-${index}`}>
          <TextField
            fullWidth
            name="key"
            label="Attribute Name"
            color="warning"
            value={property.key}
            onChange={event => handlePropertyChange(event, index)}
            disabled={isDisabled} />

          <TextField
            fullWidth
            name="value"
            label="Attribute Value"
            color="warning"
            value={property.value}
            onChange={event => handlePropertyChange(event, index)}
            disabled={isDisabled} />

          {!isDisabled &&
            <div className='property-buttons'>
              <Tooltip title="Delete Attribute" placement="top">
                <IconButton aria-label="remove" onClick={() => removeProperty(index)} style={{ padding: 0 }}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Attribute">
                <IconButton aria-label="remove" onClick={() => addProperty(index)} style={{ padding: 0 }}>
                  <AddCircleOutlineOutlinedIcon color='warning' />
                </IconButton>
              </Tooltip>
            </div>}
        </div>
      );
    })}
  </>;
};

Metadata.propTypes = {
  reward: PropTypes.object,
  course: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Metadata;
