import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { courseType } from 'utils/constants';


const Media = ({reward, course, isDisabled, onFileUpload, onChange}) => {
  if (isDisabled) { return null; }

  const certificate = course.category === courseType.COURSE.value;
  const mediaTypeImage = reward.media_type ? reward.media_type === "image" : true;

  const handleFileUpload = (event) => {
    if (!event.target.files) {
      return;
    }

    const media = event.target.name;
    const file = event.target.files[0];
    let newReward = {...reward};
    newReward[`${media}_file`] = file;
    newReward[`${media}_filename`] = file && file.name;
    onFileUpload(newReward);
  };

  const handleMediaTypeChange = (e, value) => {
    if (value) {
      onChange({ target: { name: "media_type", value: "image" } });
    } else {
      onChange({ target: { name: "media_type", value: "video" } });
    }
  }

  return <>
    <h3 className="new-form-section">Media</h3>

    {!certificate &&
      <Stack direction="row" spacing={0.5} alignItems="center" alignSelf="center">
        <Typography>Video</Typography>
        <Switch color="warning"
          checked={mediaTypeImage}
          onChange={handleMediaTypeChange} />
        <Typography>Image</Typography>
      </Stack>}

    <div className={`new-form-field column`}>
      <Button
        fullWidth
        component="label"
        variant="outlined"
        color="warning"
        startIcon={<UploadFileIcon />}
      >
        Upload image *
        <input name="image" type="file" accept="image/*" hidden onChange={ handleFileUpload } />
      </Button>
      <p>{reward.image_filename}</p>
    </div>

    {!mediaTypeImage &&
      <div className={`new-form-field column`}>
        <Button
          fullWidth
          component="label"
          variant="outlined"
          color="warning"
          startIcon={<UploadFileIcon />}
        >
          Upload video *
          <input name="video" type="file" accept="video/*" hidden onChange={ handleFileUpload } />
        </Button>
        <p>{reward.video_filename}</p>
      </div>}

    {certificate &&
      <div className={`new-form-field`}>
        <Box>
          <FormControlLabel
            label={`Dynamic Elements ${!reward.image_filename && !reward.image_url ? '- upload image' : ''}`}
            disabled={reward.image_filename === '' && reward.image_url === undefined}
            control={<Checkbox
              name="template"
              checked={reward.template}
              onChange={ onChange } />} />
        </Box>
      </div>}
  </>;
};

Media.propTypes = {
  reward: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Media;


