import React from "react";
import PropTypes from "prop-types";

import Image from "./OrderCardImage";
import Header from "./OrderCardHeader";
import Details from "./OrderCardContent";
import FullwidthCard from "../FullwidthCard";

const OrderCard = ({ item, hideDetails }) => {
  return (
    <FullwidthCard image={<Image item={ item } 
                                 hideDetails={ hideDetails } />}
                   header={<Header item={ item } />}
                   content={<Details item={ item } />} />
  );
};

OrderCard.propTypes = {
  item: PropTypes.object,
  hideDetails: PropTypes.bool,
};

export default OrderCard;
