import React from 'react';
import PropTypes from 'prop-types';

const AttachedImage = ({imageUrl}) => {
  return (
    <img
        className='post-card-attached-image'
        src={imageUrl}
    />
  )
}

AttachedImage.propTypes = {
    imageUrl: PropTypes.string,
};

export default AttachedImage;