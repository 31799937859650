import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { t } from 'i18n/index';

const FrequencySwitcher = ({ frequency, onFrequencyChange, frequencies }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, frequency) => {
    if (event.target.value === undefined) {
      setAnchorEl(null);
    } else {
      onFrequencyChange(frequency);
      setAnchorEl(null);
    }
  };

  const hasMultiplePricings = frequencies.length > 1;

  return (
    <div >
      {hasMultiplePricings ? (
        <div>
          <p className="zero frequency-switcher" onClick={handleClick}>
            {t(`globalVariables:${frequency}`)}{anchorEl ? <KeyboardArrowUp size='small'/> : <KeyboardArrowDown size='small'/>}
          </p>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: "60vh",
                minWidth: "225px",
              },
            }}
          >
            {frequencies.map(({ id, frequency }) => (
              <MenuItem
                className={"MuiMenu-item"}
                key={id}
                value={id}
                onClick={(event) => handleClose(event, frequency)}
              >
                {t(`globalVariables:${frequency}`)}
              </MenuItem>
            ))}
          </Menu>
        </div>
      ) :
        <p className="zero">{t(`globalVariables:${frequency}`)}</p>
      }
    </div>
  );
};

FrequencySwitcher.propTypes = {
  frequency: PropTypes.string,
  onFrequencyChange: PropTypes.func,
  frequencies: PropTypes.array,
};

export default FrequencySwitcher;