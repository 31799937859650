import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n/index';
import Button from '@mui/material/Button';

const BuyButton = ({onClick, oneOffPayment, tier}) => {
  
  return (
    <Button className='buy-button' fullWidth variant="contained" onClick={onClick}>
      {!tier ? 
        t('publicClaimPage:buy') :
          oneOffPayment  ?
          t('organizationPage:subscriptionButtonText') : 
          t('organizationPage:buyMembershipButtonText')}
    </Button>
  )
}

BuyButton.propTypes = {
    onClick: PropTypes.func,
    oneOffPayment: PropTypes.bool,
    tier: PropTypes.bool,
};

export default BuyButton;