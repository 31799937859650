import React from 'react';
import PropTypes from 'prop-types';

import PostCard from 'components/Cards/PostCard/PostCard';
import LockedPostCard from 'components/Cards/PostCard/LockedPostCard';

const PostsGrid = ({ data }) => {    

    return (   
        data.map((item) => {

            return (
                <div key={item.id}>
                    {item.body ? 
                        <PostCard selectedPost={ item } />
                        :
                        <LockedPostCard selectedPost={ item } />
                    }
                </div>
            );
        })
    );
};

PostsGrid.propTypes = {
    data: PropTypes.object,
};

export default PostsGrid;
