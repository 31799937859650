import React, { useState } from "react";
import { useController, useSuspense } from "@rest-hooks/react";
import { useHistory, useParams } from "react-router-dom";

import { useToast } from "utils/context/ToastContext";
import PostResource from "resources/organization/PostResource";

import StatusModal from "components/StatusModal";
import Layout from "components/layouts";
import PostForm from "./form";
import customToast from "utils/customToast";

const pageInfo = {
  name: "Update Content",
};

const UpdatePost = () => {

  const { fetch } = useController();
  const history = useHistory();
  const params = useParams();

  const organizationId = parseInt(params.organizationId);
  const postId = parseInt(params.postId);

  const post = useSuspense(PostResource.detail(),{ organization_id: organizationId, id: postId })

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { setOpen, setMessage, setSeverity } = useToast();

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (body) => {
    if (body.trim() === "") {
      throwError("Please provide a title.");
    }
  };

  const handleError = async (error) => {
    if (error.response) {
      const { errors } = await error.response.json();
      const errorMessage = errors ? errors.base[0] : error.message;
      throwError(errorMessage);
    } else {
      throwError(error.message);
    }
  };

  const throwError = (errorMessage) => {
    setError(errorMessage);
    handleOpenErrorModal();
  };

  const createFormData = (post) => {
    const formData = new FormData();
    const fields = [
      { key: "body", value: post.body },
      { key: "reward_ids", value: JSON.stringify(post.reward_ids) },
    ];

    fields.forEach((field) => {
      formData.append(field.key, field.value);
    });

    if (post.image_files && post.image_files.length > 0) {
      post.image_files.forEach((file) => {
        formData.append("images[]", file);
      });
    }
    if (post.video_files && post.video_files.length > 0) {
      post.video_files.forEach((file) => {
        formData.append("videos[]", file);
      });
    }

    return formData;
  };

  const handleSubmit = async (e, post) => {
    setLoading(true);
    try {
      e.preventDefault();
      handleValidation(post.body);
      const formData = createFormData(post);

      const { id } = await fetch( PostResource.update(), { id: postId, organization_id: organizationId }, formData );
      if (id) {
        await fetch(PostResource.list(), { organization_id: organizationId });
        customToast('success', 'Content updated successfully', setOpen, setSeverity, setMessage);
        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  return (
    <Layout
      context="teacher"
      back={true}
      pageInfo={pageInfo}
      activeMenuItem='content'
    >
      <PostForm record={post}
                organizationId={organizationId}
                onSubmit={handleSubmit}
                loading={loading}
                />
      {error && <StatusModal message={error} open={openErrorModal} onClose={handleCloseErrorModal} />}
    </Layout>
  );
};



export default UpdatePost;
