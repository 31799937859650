import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";

import OrderResource from 'resources/user/OrderResource'

import PaymentOptions from './PaymentOptions';
import StripePaymentForm from './StripePaymentForm';
import AdyenPaymentForm from './AdyenPaymentForm';
import UtrustPaymentForm from './UtrustPaymentForm';
import EupagoPaymentForm from './EupagoPaymentForm';

import { findRewardInstance } from "utils/claimActions/payments/findRewardInstance";
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';
import processDevPayments from 'utils/processDevPayments';

import { t } from "i18n/index";

const PaymentProviderSelector = ({ gift,
                                   giftData,
                                   invoiceData,
                                   reward,
                                   amount,
                                   paymentProvider,
                                   handlePaymentProvider,
                                   paymentIntentId,
                                   handlePaymentIntentId,
                                   handleError,
                                   handleErrorFindingReward,
                                   handleStep,
                                   onClose,
                                   pricingId
                                  }) => {

  const [method, setMethod] = useState("");
  const [stripePublishableKey, setStripePublishableKey] = useState("");

  const [message, setMessage] = useState("");
  const [finalAmount, setFinalAmount] = useState(undefined);
  const [finalPrice, setFinalPrice] = useState(undefined);

  const [sessionData, setSessionData] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [requiresUserPrompt, setRequiresUserPrompt] = useState(false);

  const [userPhone, setUserPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { fetch } = useController();

  const createPaymentIntent = async (provider, userPhone) => {
    try {
      const res = await fetch(
        OrderResource.create(),
        {
          course_id: reward.course_id,
          organization_id: reward.organization.id,
        },
        {
          items: [
            JSON.stringify({
              reward_id: parseInt(reward.id),
              amount: amount,
              provider: provider,
              gift_data: giftData,
              invoice_data: invoiceData,
              user_phone: userPhone,
              pricing_id: pricingId,
            }),
          ],
        }
      );
      return res;
    } catch (error) {
      handleError(error);
    }
  };

  const handleStripePaymentIntent = useCallback(async () => {
    handlePaymentProvider("stripe");
    const res = await createPaymentIntent("stripe");
    if (res) {
      const errors = res.client_secret.includes("An error occured.");
      if (!errors) {
        setFinalAmount(res.final_amount);
        setFinalPrice(res.final_price);
        handlePaymentIntentId(res.client_secret);
      } else {
        setMessage(res.client_secret);
      }
    }
  }, []);

  const handleAdyenPaymentIntent = useCallback(async (method) => {
    setMethod(method);
    handlePaymentProvider("adyen");
    const res = await createPaymentIntent("adyen");
    if (res) {
      const errors = res.errors;
      if (!errors) {
        setFinalAmount(res.final_amount);
        setFinalPrice(res.final_price);
        handlePaymentIntentId(res.id);
        setSessionData(res.session_data);
        setSessionId(res.session_id);
      } else {
        setMessage(errors);
      }
    }
  }, []);

  const handleUtrustPaymentIntent = useCallback(async () => {
    handlePaymentProvider("utrust");
    const res = await createPaymentIntent("utrust");
    if (res) {
      const errors = res.redirect_url.includes("An error occured.")
      if (!errors) {
        setFinalAmount(res.final_amount);
        setFinalPrice(res.final_price);
        handlePaymentIntentId(res.id);
        setRedirectUrl(res.redirect_url);
        if (res.final_amount === amount) {
          window.open(res.redirect_url, "_blank");
        }
      } else {
        setMessage(res.redirect_url);
      }
    }
  }, []);

  const handleDevPaymentIntent = async () => {
    const res = await createPaymentIntent("dev");
    processDevPayments(res.id);
    handleStep(4);
    findRewardInstance(res.id, 240, 5000, fetch, history, handleErrorFindingReward);
  };

  const handleEupagoPaymentIntent = () => {
    handlePaymentProvider("eupago")
  };

  const handleEupagoPayment = useCallback(async () => {
    const res = await createPaymentIntent("eupago", userPhone);
    if (res) {
      const {id, errors} = res;
      if (!errors) {
        if (!gift) {
          handleStep(4);
          findRewardInstance(id, 240, 5000, fetch, history, handleErrorFindingReward);
        } else {
          handleStep(5);
        }
      } else {
        setLoading(false);
        handleStep(3);
        setMessage(errors);
      }
    }
  }, [userPhone]);

  const showSelector = !paymentProvider;
  const {claimLimit, currency} = reward;
  const currencySign = currencySymbol[currency];

  useEffect(() => {
    const handleUserPrompt = () => {
      if (finalAmount > 0 && finalAmount < amount) {
        setMessage(
          `${t("checkoutForm:finalAmountHigherThanZero", { claimLimit, amount, finalAmount })}`
          + (paymentProvider === "utrust" ? ` ${t("checkoutForm:priceUpdated", { finalPrice, currencySign })}` : "")
        );
        setRequiresUserPrompt(true);
      }
    }
    if (finalAmount) {
      handleUserPrompt()
    }
  }, [finalAmount, finalPrice]);

  return (
    <div>
      {showSelector &&
        <PaymentOptions   handleStripePaymentIntent={ handleStripePaymentIntent }
                          handleAdyenPaymentIntent={ handleAdyenPaymentIntent }
                          handleUtrustPaymentIntent={ handleUtrustPaymentIntent }
                          handleEupagoPaymentIntent={ handleEupagoPaymentIntent }
                          handleDevPaymentIntent ={ handleDevPaymentIntent }
                          organizationId={ reward.organization.id }
                          onStripePublishableKeyChange={ setStripePublishableKey }
                          handleError={ handleError }
                          />
      }
      {paymentProvider === "stripe" &&
        <StripePaymentForm clientSecret={ paymentIntentId }
                          gift={ gift }
                          finalPrice={ convertPriceFromCents(finalPrice) }
                          currencySymbol={ currencySign }
                          organizationId={ reward.organization.id }
                          handleStep={ handleStep }
                          handleMessage={ setMessage }
                          handleErrorFindingReward={ handleErrorFindingReward }
                          publishableKey={ stripePublishableKey }
                          />
      }
      {paymentProvider === "adyen" &&
        <AdyenPaymentForm method={ method }
                          paymentIntentId={ paymentIntentId }
                          sessionData={ sessionData }
                          sessionId= { sessionId }
                          gift={ gift }
                          finalPrice={ finalPrice }
                          currency={ currency }
                          organizationId={ reward.organization.id }
                          handleStep={ handleStep }
                          handleMessage={ setMessage }
                          handleErrorFindingReward={ handleErrorFindingReward }
                          />
      }
      {paymentProvider === "utrust" &&
        <UtrustPaymentForm paymentIntentId={ paymentIntentId }
                          redirectUrl={ redirectUrl }
                          requiresUserPrompt={ requiresUserPrompt }
                          handleRequiresUserPrompt={ setRequiresUserPrompt}
                          onClose={ onClose }
                          gift={ gift }
                          handleStep={ handleStep }
                          handleMessage={ setMessage }
                          handleErrorFindingReward={ handleErrorFindingReward }
                          />
      }
      {paymentProvider === "eupago" &&
        <EupagoPaymentForm userPhone={ userPhone }
                          loading={ loading }
                          handleLoading= { setLoading }
                          handleUserPhone={ setUserPhone }
                          handleSubmit={ handleEupagoPayment }
                          onClose={ onClose }
                          />
      }
      {message && <div id="payment-message">{message}</div>}
    </div>
  );
};

PaymentProviderSelector.propTypes = {
  gift: PropTypes.bool,
  giftData: PropTypes.object,
  invoiceData: PropTypes.object,
  reward: PropTypes.object,
  amount: PropTypes.number,
  paymentProvider: PropTypes.string,
  handlePaymentProvider: PropTypes.func,
  paymentIntentId: PropTypes.string,
  handlePaymentIntentId: PropTypes.func,
  handleError: PropTypes.func,
  handleErrorFindingReward: PropTypes.func,
  handleStep: PropTypes.func,
  onClose: PropTypes.func,
  pricingId: PropTypes.number
};

export default PaymentProviderSelector;
