import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'

const UploadButton = ({Icon, inputId, name, onChange, multiple}) => {
    return (
        <IconButton onClick={() => document.getElementById(inputId).click()}>
            <Icon color="warning" />
            <input id={inputId}
                name={name}
                type="file"
                accept={`${name}/*`}
                onChange={onChange}
                multiple={multiple}  
                hidden />
        </IconButton>

    );
};

UploadButton.propTypes = {
    onClick: PropTypes.func,
    Icon: PropTypes.object,
    inputId: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
};

export default UploadButton;