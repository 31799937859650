import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TierCardContent = ({description}) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggleDescription = () => {
        setExpanded(!expanded);
      };
    
      const getDescription = () => {
        if (description.length < 300 ) {
          return description;
        } else if (expanded) {
          return `${description} See Less`;
        } else {
          return `${description.slice(0, 300)}... See More`;
        }
      };

  return (
    <p className={`fullwidth-card-content ${description.length > 300 && 'pointer'}`} onClick={handleToggleDescription}>{getDescription()}</p>
  )
}

TierCardContent.propTypes = {
    description: PropTypes.string,
};

export default TierCardContent;