import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import CourseResource from 'resources/admin/CourseResource';

import TabPanels from 'components/TabPanels';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { rewardsColumns } from 'utils/columns/admin/rewardsColumns';

const pageInfo = {
  label: "Courses",
  name: "Courses"
}

const AdminCoursesView = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const course = useSuspense(CourseResource.detail(), {id: courseId});

  pageInfo["name"] = course.title;

  return (
    <Layout context='admin'
            back={true}
            pageInfo={ pageInfo }
            activeMenuItem='collectibles'
            statOverview={
              <StatCard type='number'
                        highlight={ course.rewards.length }
                        title='Courses'
              />
            } >

      <TabPanels labels={[ "Rewards" ]} >
          <DataSelectionTable records={course.rewards}
                                  columns={rewardsColumns}
                                  checkboxSelectRows={false}
            />
      </TabPanels>

    </Layout>
)};

export default AdminCoursesView;
