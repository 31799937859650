import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';


import OrderResource from 'resources/organization/OrderResource';
import OrderDownloadResource from 'resources/organization/OrderDownloadResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WithPermission from 'components/WithPermission';
import SalesFallback from 'components/fallbacks/Sales';
import { downloadCSV } from 'utils/downloadFile';
import { ordersColumns } from 'utils/columns/ordersColumns';
import Stats from './Stats';

const OrdersTabContent = ({handleError, organizationId}) => {
  const { fetch } = useController();

  const [orders, setOrders] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orders = await fetch(OrderResource.list(), {organization_id: organizationId});
        setOrders(orders)
      } catch (error) {
        handleError(error.message);
      }
    };

    fetchData();
  }, [])

  orders.sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 });

  const completedOrders = orders.filter(order => order.status === 'completed');
  const numberOfCompletedOrders = completedOrders.length;
  const totalAmountEarned = Math.floor(completedOrders.reduce((total, order) => total + order.total_price, 0) / 100);

  const nftsSold = completedOrders.reduce((total, order) => total + order.items_amount, 0);

  const handleDownload = async () => {
    try {
      const response = await fetch(OrderDownloadResource.list(), {organization_id: organizationId});
      downloadCSV(response, 'orders.csv', 'text/csv');
    } catch (error) {
      handleError(error.message);
    }
  };

  const currency = orders.length > 0 ? orders[0].currency : 'USD';

  return (
      <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>

        <Stats stat1={ { counter: numberOfCompletedOrders } } stat2={ { counter: nftsSold } }  stat3={ { counter: totalAmountEarned } } currency ={currency} />
        <div style={{ flexGrow: 1 }}>
            <DataSelectionTable records={orders}
                                columns={ordersColumns}
                                onClick={handleDownload}
            />
        </div>

      </WithPermission>
)};

OrdersTabContent.propTypes = {
    handleError: PropTypes.func,
    organizationId: PropTypes.number,
  };

export default OrdersTabContent;
