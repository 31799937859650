import { React, useCallback } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import CourseResource from 'resources/organization/CourseResource';
import RewardResource from 'resources/organization/RewardResource';

import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';
import BadgeCard from 'components/BadgeCard';
import ActionCard from 'components/ActionCard';
import PageTop from 'components/PageTop';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Link } from '@mui/material';
import { organizationUrl } from 'utils/urls';

import Onboarding from './Onboarding';
import OrganizationResource from 'resources/organization/OrganizationResource';

const newTierUrl = (organizationId, courseId) => `/organizations/${organizationId}/memberships/${courseId}/tiers/new`;

/* eslint-disable react/prop-types */
const BadgesGrid = ({rewards, courseId, organizationId, onDelete, published}) => (
    <div className="badges-grid grid">
      { rewards.map( ({id, title, category, media_type, image_url, video_url, stats, public:isPublic, active_state, supply_limit, end_date, pricings}) => (
          <BadgeCard key={ id }
                    id={ id }
                    course_id={ courseId }
                    title={ title }
                    category={ category }
                    media_type={ media_type }
                    image_url={ image_url }
                    video_url={ video_url }
                    stats={ stats }
                    isPublic={ isPublic}
                    onDelete={ () => onDelete(id) }
                    active_state={ active_state }
                    supply_limit={ supply_limit }
                    end_date={ end_date }
                    published = { published }
                    pricings={ pricings }
          />
        ))
      }

      <ActionCard to={ newTierUrl(organizationId, courseId) }
                    Icon={ AddCircleOutlineIcon }
                    title="Add Tier" />

    </div>
);


const pageInfo = {
  name: "Memberships",
  description: "Manage your membership tiers",
  action: true
};

/* eslint-enable react/prop-types */
const Memberships = () => {
  const { fetch } = useController();
  const params    = useParams();
  const membershipId   = parseInt(params.courseId);
  const organizationId = parseInt(params.organizationId);

  const organization = useSuspense(OrganizationResource.detail(), {id: organizationId});
  const membership = useSuspense(CourseResource.detail(), {id: membershipId, organization_id: organizationId});
  const rewards    = useSuspense(RewardResource.list(), {organization_id: organizationId, course_id: membershipId});
  const tiers      = rewards.sort( (a,b) => b.active_state - a.active_state )

  const handleDelete = useCallback(
    async (rewardId) => {
      await fetch(RewardResource.delete(), { id: rewardId, course_id: membershipId, organization_id: organizationId });
    },
    [fetch],
  );

  const hasTiers = tiers.length > 0;

  const cta = <div style={{marginTop: '20px'}}>
    <Link href={ hasTiers ? organizationUrl(organizationId) : newTierUrl(organizationId, membershipId)  } target={ hasTiers ? '_blank' : null }>
      <Button variant='contained' >
        { hasTiers ? `Preview Memberships page` : `New Tier` }
      </Button>
    </Link>
  </div>

  return (
    <Layout context='teacher'
            activeMenuItem='memberships' >
      <PageTop pageInfo={ pageInfo }
                cta={ cta } />

      <TabPanels labels={ [`Membership Tiers (${tiers.length})`] } >
        { hasTiers &&
            <BadgesGrid key={0} rewards={ tiers } organizationId={ membership.organization_id } courseId={ membershipId } onDelete={ handleDelete } sellableCollectibles={true}/>
        }

      </TabPanels>

      <Onboarding hasTiers={ hasTiers }
                  hasPaymentProviders={ organization.hasPaymentProviders }
                  organizationId={ organizationId }
                  membershipId={ membershipId }
      />

    </Layout>
)};

export default Memberships;
