import React from 'react';
import PropTypes from 'prop-types';
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';

const Price = ({price, currency}) => {

  const convertedPrice = convertPriceFromCents(price);
  const priceWithCurrency = `${parseFloat(convertedPrice)} ${currencySymbol[currency]}`;

  return (
    <p className='zero'>{priceWithCurrency}</p>
  );
};

Price.propTypes = {
    price: PropTypes.number,
    currency: PropTypes.string,
};

export default Price;