import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ActiveState from './ActiveState';

const Footer = ({reward, loading, published, mysteryBox, onChange}) => {

  return <div className={`new-form-field-footer`}>
    {loading ?
      <CircularProgress color="warning" />
      :
      <Button variant="contained" color="primary" type="submit" disabled={published}>
        {!reward.id ? 'Create' : 'Save'}
      </Button>}
    {!mysteryBox &&
      <ActiveState
        active_state={reward.active_state}
        onchange={onChange} />}
  </div>;
};

Footer.propTypes = {
  loading: PropTypes.bool,
  published: PropTypes.bool,
  record: PropTypes.object,
  mysteryBox: PropTypes.bool,
  reward: PropTypes.object,
  onChange: PropTypes.func
};

export default Footer;
