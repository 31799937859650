import React from "react";
import PropTypes from "prop-types";
import VideoPreview from "components/VideoPreview";

const TierCardImage = ({ media_type, video_url, title, image_url }) => {
  return (
    <div className={"fullwidth-card-image-container"}>
        { media_type == "video" &&
            <VideoPreview className="fullwidth-card-image" url={video_url} poster={ image_url } playAudio={false} />
        }
        { media_type == "image" &&
            <img src={ image_url } className="fullwidth-card-image" alt={ `${ title } Badge` } />
        }
      </div>
  );
};

TierCardImage.propTypes = {
    media_type: PropTypes.object,
    video_url: PropTypes.bool,
    title: PropTypes.string,
    image_url: PropTypes.string,
};

export default TierCardImage;
