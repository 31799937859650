import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { rewardType } from 'utils/constants';

export const Labels = ({category, isTemplate, isPublic}) => {
  if (category === rewardType.PASS.value) return null;

  return <div className="badge-labels">
    {isTemplate &&
      <Chip label="TEMPLATE" variant="outlined" className='template' />}
    {isPublic &&
      <Chip label="PUBLIC" variant='outlined' className='public' />}
  </div>;
};


Labels.propTypes = {
  category: PropTypes.string.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool.isRequired,
};

export default Labels;
