import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PaymentProviderEditModal from './PaymentProviderEditModal';
import PaymentProviderAdminList from './PaymentProviderAdminList';
import StatusModal from 'components/StatusModal';

const PaymentProvidersForm = ({organizationId}) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const handleEditClick = (provider) => {
        setSelectedProvider(provider);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedProvider(null);
    };

    const handleError = async (error) => {
        if (error.response) {
            const { errors } = await error.response.json();
            const errorMessage = errors ? errors.base[0] : error.message;
            setError(errorMessage);
          } else {
            setError(error.message);
          }
          setOpenErrorModal(true);
    };
    
  return (
    <div>
        <PaymentProviderAdminList organizationId={organizationId} onClick={handleEditClick} handleError={handleError}/>
        {selectedProvider && <PaymentProviderEditModal open={openModal} onClose={handleCloseModal} selectedProvider={selectedProvider} organizationId={organizationId} handleError={handleError}/>}
        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ () => setOpenErrorModal(false) }/>}
    </div>
  );
};

PaymentProvidersForm.propTypes = {
    organizationId: PropTypes.number,
};

export default PaymentProvidersForm;