import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material';

const OutlinedButton = ({action, description, fullWidth}) => {
  return (
    <Button fullWidth={fullWidth}
            variant="outlined"
            color="warning"
            onClick={action}
    >
        {description}
    </Button>
  )
};

OutlinedButton.propTypes = {
    action: PropTypes.func,
    description: PropTypes.string,
    fullWidth: PropTypes.bool,
};

export default OutlinedButton;