import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RewardPreview from 'components/RewardPreview/index';
import TemplateModal from 'containers/teacher/Reward/Wizard/Template/TemplateModal';

import { courseType } from 'utils/constants';

import Chip from '@mui/material/Chip';

import Title from './Form/Title';
import Pricing from './Form/Pricing';
import Media from './Form/Media';
import LinkSettings from './Form/LinkSettings';
import Metadata from './Form/Metadata';
import Footer from './Form/Footer';

import { emptyReward, booleanAttrs } from './Form/consts';

function initReward(record, course) {
  return {
    ...emptyReward[course.category],
    ...record,
  };
}

const RewardForm = ({record={}, course, onSubmit, isDisabled, loading, mysteryBoxReward, published}) => {
  const [reward, setReward] = useState(initReward(record, course));
  const [open, setOpen] = useState(false);
  const [templateVars, setTemplateVars] = useState({});

  const mysteryBox = course.category === courseType.MYSTERYBOX.value;

  const handleRewardChange = (event) => {
    let newReward = {...reward};
    const {name, value, checked} = event.target;

    if (name === 'template' && checked) {
      setOpen(true);
    }

    newReward[name] = booleanAttrs.includes(name) ? checked : value;
    setReward(newReward);
  };

  const handleCloseTemplateModal = () => {
    setOpen(false);
  };

  const handleTemplateUpdate = (vars) => {
    setTemplateVars(vars);
  };

  return (
    <div className='reward-creation-container'>
      <form id="admin-rewards-add"
        onSubmit={ (e) => onSubmit(e, reward, templateVars) } >

        <div className="new-reward-wrapper">
          <Title reward={reward} isDisabled={isDisabled} onChange={handleRewardChange} />

          <Pricing pricings={reward.pricings}
                   collectionType={course.category}
                   onChange={handleRewardChange} />

          <Media reward={reward}
                  course={course}
                  isDisabled={isDisabled}
                  onFileUpload={setReward}
                  onChange={handleRewardChange} />

          <LinkSettings reward={reward}
                        course={course}
                        mysteryBoxReward={mysteryBoxReward}
                        isDisabled={isDisabled}
                        published={published}
                        onChange={handleRewardChange} />

          { !mysteryBoxReward &&
            <Metadata reward={reward}
                      course={course}
                      isDisabled={isDisabled}
                      onChange={handleRewardChange} />
          }

          <Footer loading={loading}
                  published={published}
                  record={record}
                  mysteryBox={mysteryBox}
                  reward={reward}
                  onChange={handleRewardChange} />

        </div>
      </form>

      <div className='reward-preview'>
        <div className='badge-labels'>
            <Chip label="PREVIEW" variant='outlined' className='preview' />
        </div>

        <RewardPreview  title={ reward.title === '' ? 'Title' : reward.title }
                        image={ reward.image_file }
                        image_url={reward.image_url}
                        description={ reward.description }
                        metadata={ reward.metadata.filter( ({key, value}) => key && value) }
                        created_at={ Date.now()}
                        reward_type={ reward.category }
                        organization={ course.organization }
                        activity_title={ course.title }
                        activity_type={ course.category }
                        media_type={ reward.media_type }
        />
      </div>
      <TemplateModal onSave={handleTemplateUpdate} imgSource = {reward['image_file'] ? window.URL.createObjectURL(reward['image_file']) : reward.template_image_url } open={open} title={reward.title === '' ? 'Title' : reward.title} onClose={ handleCloseTemplateModal } style={ { width: '80%', height: '80%' } }/>
    </div>
  );
};

RewardForm.propTypes = {
  record: PropTypes.object,
  onSubmit: PropTypes.func,
  course: PropTypes.object,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  mysteryBoxReward: PropTypes.bool,
  permissions: PropTypes.object,
  published: PropTypes.bool,
};

export default RewardForm;
