import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel, InputAdornment, Stack, TextField, Typography,
  FormGroup, Switch, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { priceIndexFromPricings, pricingsFromPriceIndex } from 'utils/rewards';
import { currencies, frequencies, payableTypes, priceErrors, priceRegex } from './consts';

const labels = {
  "collectible": "Price *",
  "monthly": "Monthly price",
  "quarterly": "Quarterly price",
  "yearly": "Yearly price",
  "lifetime": "One-off / Lifetime price",
  "format": "Please use this format: 25.50",
};

/* eslint-disable react/prop-types */
const PriceField = ({frequency, price, currency, label, isDisabled, style, errors, handleChange}) => (
  <TextField style={style}
    error={errors[ frequency ]}
    name={frequency}
    label={errors[ frequency ] ? labels["format"] : (label || labels[frequency])}
    color="warning"
    value={price || ''}
    onChange={handleChange}
    onPaste={event => event.preventDefault()}
    disabled={isDisabled}
    InputProps={{
      endAdornment: <InputAdornment>{currencies[currency].icon}</InputAdornment>,
    }} />
  );

const Pricing = ({pricings, collectionType, onChange}) => {
  if ( !payableTypes.includes(collectionType) ) { return null; }

  const [errors, setErrors] = useState(priceErrors);
  const [advancedMode, setAdvancedMode] = useState(pricings != null && pricings.length > 1);
  const [currency, setCurrency] = useState(pricings != null && pricings.length > 0 ? pricings[0].currency : "USD");
  const [prices, setPrices] = useState(priceIndexFromPricings(pricings));

  const free = (pricings != null && pricings.length === 0);
  const defaultFrequency = collectionType === "membership" ? "monthly" : "lifetime";

  const handleFreeChange = (e, value) => {
    if (value) {
      setAdvancedMode(false);
      setPrices({ [defaultFrequency]: "" });
      onChange({target: {name: "pricings", value: []}});
    } else {
      onChange({target: {name: "pricings", value: null}});
    }
  };

  const handleCurrencyChange = (event) => {
    const {value} = event.target;
    setCurrency(value);

    const newPricings = pricingsFromPriceIndex(prices, value);

    onChange({target: {name: "pricings", value: newPricings }});
  };

  const handleChange = (event) => {
    const {name, value} = event.target;

    const newPrices = { ...prices, [name]: value };
    setPrices(newPrices);

    if (value === "") {
      setErrors({...errors, [name]: false});

    } else if (!priceRegex.test(value)) {
      setErrors({...errors, [name]: true});
      return;
    }
    setErrors({...errors, [name]: false});

    const newPricings = pricingsFromPriceIndex(newPrices, currency);
    onChange({target: {name: "pricings", value: newPricings }});
  };

  return <>
    <div className={`new-form-field`}>
      <FormGroup>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <h3 style={{ paddingRight: '30px'}}>Pricing</h3>
          <FormControlLabel control={<Switch
            color="warning"
            checked={ free }
            onChange={handleFreeChange} />} />
            <Typography>Free</Typography>
        </Stack>
      </FormGroup>
    </div>

    <div className={`new-form-field`}>
      <PriceField frequency={ defaultFrequency }
                  price={ prices[ defaultFrequency] }
                  currency={ currency }
                  label={ defaultFrequency === 'lifetime' ? labels["collectible"] : null }
                  isDisabled={ free }
                  style={{width: "50%"}}
                  errors={errors}
                  handleChange={handleChange} />

      <FormControl style={{width: "50%"}}>
        <InputLabel color="warning">Currency</InputLabel>
        <Select
          label="Currency"
          name="currency"
          color="warning"
          value={ currency }
          onChange={ handleCurrencyChange }
          disabled={ free }
        >

          { Object.values(currencies).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>

    { collectionType === "membership" &&
      <>
      { (!advancedMode && !free) &&
        <Typography className='other-frequencies' variant="subtitle2" onClick={() => setAdvancedMode(true)}>Other frequencies and discounts <span>+</span></Typography>
      }

      { advancedMode &&
        <>
          { frequencies.slice(1).map( (frequency, index) => (
              <PriceField key={index}
                        frequency={ frequency }
                        price={ prices[frequency] }
                        currency={ currency }
                        isDisabled={ free }
                        style={{width: "100%", marginTop: "10px"}}
                        errors={errors}
                        handleChange={handleChange} />
          ))}
        </>
      }
      </>
    }
  </>;

};

Pricing.propTypes = {
  pricings: PropTypes.array,
  collectionType: PropTypes.string,
  onChange: PropTypes.func,
};

export default Pricing;

