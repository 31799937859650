import React, {useState} from 'react';
import { useParams } from "react-router-dom";

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import StatusModal from 'components/StatusModal';

import RewardInstancesTabContent from './RewardInstancesTabContent';
import CollectionsTabContent from './CollectionsTabContent';
import OfferInstancesTabContent from './OfferInstancesTabContent';
import OrdersTabContent from './OrdersTabContent';

import { t } from 'i18n/index';

const pageInfo = {
  name: t('pageInfo:reports'),
  description: t('pageInfo:reportsDescription'),
}

const RewardInstance = () => {
  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
  };

  const handleError = (message) => {
    setError(message);
    handleOpenErrorModal();
  };

  return (
    <Layout context='teacher'
            activeMenuItem='analytics'
            pageInfo={pageInfo}>

      <TabPanels labels={[ "Claimed NFTs", "Orders", "Collections Stats",  "Redeemed Offers"]} >

        <RewardInstancesTabContent key={0} handleError={handleError} organizationId={organizationId} />

        <OrdersTabContent key={3} handleError={handleError} organizationId={organizationId} />

        <CollectionsTabContent  key={1} handleError={handleError} organizationId={organizationId} />

        <OfferInstancesTabContent key={2} handleError={handleError} organizationId={organizationId} />

      </TabPanels>

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
)};

export default RewardInstance;
