const labels = {
    en: {

        rewardType:{
            behaviour: 'NFT',
            achievement: 'Certificate',
            collectible: 'Collectible'
        },

        claimState:{
                collectorsPortal_Reward_Claimed_OnChain: 'View NFT',
                collectorsPortal_Reward_Claimed_OffChain: 'View NFT'
        },

        studentProfile: {
            collectorsPortal_Profile: "Profile",
            collectorsPortal_Connect: "Connect Wallet",
            collectorsPortal_ClaimReward: "Claim NFT",
            collectorsPortal_Claimed: "Claimed",
            collectorsPortal_Unclaimed: "Unclaimed",
            collectorsPortal_Footer: "👉 Do you also want to create exclusive NFTs for your community?",
            collectorsPortal_FooterLink: "Click here to build and launch your collection in 5 minutes."
        },

        activeMenu: {
            sidebar_Rewards: "Your collection",
            sidebar_Offers: "Offers",
            sidebar_Orders: "Orders",
            sidebar_Settings: "Settings",
            sidebar_Help: "Help",
        },

        settings_labels: {
            merch_drops: "Merchandise",
            personal_details: "Personal Details"
        },

        merch: {
            delivery_details: "Delivery Details",
            details_updated: "Merch drop details updated",
            incomplete_address: "Please tell us your full address",
            shirt_size: "T-shirt Size *",
            preferences_details: "Preferences",
            redirectButton: "Continue"
        },

        actionsMenu:{
            actionsMenuButton: "Disconnect Wallet",
        },

        connectModalAddress:{
            addWalletModal_Title: "Insert your Polygon Address",
            firstContentLabelExplication: "Please insert your address to receive your NFTs.",
            secondContentLabelExplication: "ENS addresses also work.",
            inputLabelDefaul: "Wallet Address",
            connectModalButtonCancel: "Cancel",
            connectModalButtonConnect: "Connect",
            nearMessage: "NEAR Wallet Address",
            polygonMessage: "Ethereum/Polygon Wallet Address",
            nearOrPolygonMessage: "Ethereum/Polygon or NEAR Wallet Address",
            errorMessage: "Please provide a valid wallet address.",
        },

        connectLaterModal: {
            addWalletModal_TitleLater: "Associate Web3 Wallet?",
            connecLaterFirstContentLabelExplication: "One last step to claim your unique Digital Collectible!",
            connecLaterSecondContentLabelExplication: "If you have a Web3 Wallet (like Metamask) you can withdraw your collectible now, as an NFT on-chain.",
            connecLaterThirdContentLabelExplication: "If you don’t have one, don’t worry! You can access your collectible through Crowdclass, and associate a Web3 Wallet later.",
            addWeb3Modal_Later: "No, I'll do it later!",
            addWeb3Modal_ConnectNow: "Connect to Web3 Now",
        },

        shareRewardModal: {
            title: "Congratulations!🎉",
            description: "You are the new owner of",
            defaultButton: "View and share your Collectible here!",
            downloadButton: "Download PDF",
            certificateButton: "View and share!",
        },

        tooltip:{
            logout: "logout",
        },

        dropMenuOptions: {
            collectorsPortal_Reward_Menu_ClaimOnChain: "Claim On-Chain",
            collectorsPortal_Reward_Menu_Share: "Share NFT",
        },

        loginPage: {
            login_EnterMailText: "Login to see and organize your digital collection.",
            login_ForgotPassword: "Forgot your password?",
            login_KeepMeLogged: "Keep me logged in",
            login_Password: "Password",
            login_Reset: "Reset it here.",
            loginModal_LogMeIn: "Log me in",
            loginModal_NoAccountFirst: "Don't have an account?",
            loginModal_NoAccountSecond: "Create one",
            loginModal_Title: "See your collection!",
            loginPage_LogInButton: "Access My Collection",
            loginPage_Title: "Manage your Digital Collection",
        },

        signUpPage: {
            signUpModal_AgreeTerms: "I agree to Crowdclass‘s Terms & Conditions and Privacy Policy",
            signUpModal_AlreadyHaveAccount: "Already have an account?",
            signUpModal_ClaimText: "This NFT is issued using Crowdclass Platform. Sign-up to claim it and manage your collection there.",
            signUpModal_Continue: "Continue",
            signUpModal_GetStarted: "Get Started!",
            signUpModal_Name: "Name *",
            signUpModal_SignIn: "Sign in",
        },

        passwordReset: {
            forgotPageTitle: "Forgot your Password?",
            forgotPageDescription: "It happens to the best of us – we’ll help you reset it and get back on track.",
            forgotPageButton: "Reset Password",
            newAccountPageTitle: "Welcome to Crowdclass!",
            newAccountPageDescription: "Create a password to see and share your new NFT and manage your collection!",
            newAccountPageButton: "Sign in",
            sendingInstructions: "Sending...",
            requestInstructions: "Send reset instructions",
            resetInstructions: "An email with instructions was sent to the email address you provided. Please check your email.",
            errorEmailMissing: "Please provide a valid email address",
            errorMissingPassword: "Please provide a valid password.",
            errorMatchingPasswords: "Passwords do not match.",
            success: 'You have sucessfully reset your password. 🎉',
            claiming: 'Claiming your badge now.',
            redirecting: 'Redirecting to your profile.',
        },

        connectWallet: {
            title: "Connect Wallet",
            chooseWallet: "Choose a wallet to connect",
        },

        tcLabel:{
            byusing: "By using Crowdclass you agree to our",
            firstPhrase:"I agree to Crowdclass‘s",
            secondPhrase:"Terms & Conditions",
            thirdPhrase: "and",
            fourthPhrase: "Privacy Policy",
            fifthPhrase: "Terms of use",
            sixthPhrase: "Privacy Policy",
        },

        metadata: {
            metadata_ActivityTitle: "Collection Title",
            metadata_ActivityType: "Collection Type",
            metadata_ClaimedAt: "Claimed At",
            metadata_IssuedAt: "Issued At",
            metadata_IssuerName: "Issuer Name",
            metadata_TokenType: "Token Type",
        },

        publicReward: {
            publicRewardPage_by: "by",
            publicRewardPage_ShareIt: "Share it with the world",
            openMysteryBox: "Open Mystery Box!",
        },

        publicClaimPage: {
            aboutCrowdclassTextFirst: "Crowdclass helps brands, organizations & creators to connect with their audience using Digital Collectibles & NFTs.",
            aboutCrowdclassTextSecond: "Want to learn more?",
            aboutCrowdclasscheckItHere: "Check it here",
            addBadgeFirstText: "Add this unique badge to your collection.",
            addCollectibleFirstText: "Add this unique collectible to your collection.",
            addCertificateFirstText: "Add this unique certificate to your collection.",
            addPassFirstText: "Add this membership pass to your collection.",
            addBadgeSecondText: "Takes less than 1 minute.",
            buy: "Buy now",
            claimYourPass: "Claim your Pass",
            claimYourBadge: "Claim your Badge",
            claimYourCollectible: "Claim your Collectible",
            claimYourCertificate: "Claim your Certificate",
            currentlyLoggedIn: "You´re currently logged in as",
            description: "Description",
            logOut: "Log out?",
            profile: "See Collection.",
            alreadyIssuedText: `You have already been issued this NFT the maximum number of times.`,
            notEligibleText: "Your account is not eligible to claim this NFT. Please contact the organization if you think this is not correct.",
            preApprovedText: "*Your e-mail, or Crowdclass account, must be eligible to claim this NFT. Please contact the organization if you’re not being able to claim it.",
            priceDescription: "each",
            unavailable: "This NFT is no longer available.",
            onboardingSubtitle1: "Crowdclass collectibles",
            onboardingText1: "Crowdclass collectibles are exclusive digital gifts issued as NFTs, celebrating unique life moments, experiences and achievements.",
            onboardingSubtitle2: "Never heard about NFTs?",
            onboardingText2: "NFT (Non-Fungible-Tokens) are unique and verifiable digital tokens on the blockchain. They can represent different digital assets, are fully owned by you, and cannot be forged. The process to claim an NFT with Crowdclass is simple and no wallet or web3 experience is required.",
            claiming: "Claiming",
        },

        checkoutForm: {
            finalAmountHigherThanZero: "A single user can only have {{claimLimit}} instances of this NFT. As such your order amount was reduced from {{amount}} to {{finalAmount}}.",
            paymentSucceeded: "Payment succeeded!",
            paymentProcessing: "Your payment is processing.",
            paymentFailed: "Your payment was not successful, please try again.",
            paymentFailedDefault: "Something went wrong, please try again. If the problem persists please contact the collection administrator.",
            collectibleUnavailable: "This NFT is no longer available.",
            pay: "Pay {{finalPrice}} {{currencySymbol}}",
            price: "Price: ",
            inStock: "In stock. Max supply: ",
            thankYouForPayment: "Thank you for your payment",
            lookingForRewardInstance: "You will be redirected to your profile shortly.",
            rewardInstanceNotFound: "Something went wrong, please click HERE to be redirected to your profile and contact the collection administrator.",
            priceUpdated: "Your order price has also been updated to {{finalPrice}} {{currencySymbol}}.",
            proceedToUtrust: "Take me to xMoney",
            gift: "This is a gift.",
            giftBeingSent: "We will shortly send you an email with your order details and confirmation that your gift was sent.",
            preparingYourOrder: "Preparing your order...",
            orderStarted: "Your order has been started.",
            utrustIntro: "You will be or have been redirected to the xMoney payment widget, compatible with any major crypto wallet. Please follow the instructions and confirm your purchase below when all the steps are completed.",
            utrustInstructions: "If you have not been redirected to the xMoney payment widget, ensure your browser is not blocking pop-ups from Crowdclass or ",
            changedMyMind: "Changed my Mind",
            paymentComplete: "Complete Payment",
            utrustAwaitingConfirmationParagraph1: "We are waiting for payment confirmation by xMoney.",
            utrustAwaitingConfirmationParagraph2: "You will be redirected to your profile soon after it's complete.",
            utrustAwaitingConfirmationParagraph3: "You can also close this window and return later to claim the NFT after payment is confirmed.",
            mbwayAwaitingConfirmationParagraph1: "We are waiting for confirmation that your payment was successful.",
            mbwayAwaitingConfirmationParagraph2: "Please open your MB WAY app and confirm the payment there.",
            processingOrder: "We are processing your order.",
            googlePayFailed: "Google Pay is not available. Please choose a different payment method.",
            phoneNumber: "Phone Number",
        },

        giftModal:{
            title: "Gift this NFT to someone.",
            message: "Please add a message and details below to whom you want to give this NFT. The NFT will be sent by email.",
        },

        giftForm:{
            nameMissing: "Please tell us the name of the penson you would like to send this NFT to.",
            emailMissing: "Please tell us the email address of the person you would like to send this NFT to.",
            senderEmailMissing: "Please tell us your email address.",
            message: "Special message (400 char max)",
            senderName: "Your name ",
            senderEmail: "Your email * ",
            from: "From",
            to: "To",
            proceed: "Proceed to payment",
            name: "Recipient's name *",
            email: "Recipient's email *",
        },

        invoiceForm:{
            title: "Billing Details",
            message: "Please add your details below.",
            personalDetails: "Personal Details",
            senderName: "Name *",
            vat: "VAT no.",
            contact: "Contact details",
            senderEmail: "Email *",
            address: "Billing address",
            streetAddress: "Street address",
            city: "City",
            state: "State/County",
            postCode: "Postal Code",
            country: "Country",
            nameMissing: "Please tell us your name.",
            emailMissing: "Please tell us your email address.",
            countryMissing: "Please tell us your country.",
            userExists: "An account already exists with the given email. Please use a different email or login with the existing account.",
            errorValidatingEmail: "There was an error validating your email address. Please try again.",
        },

        paymentProviderSelector:{
            title: "Payment Methods",
            message: "Please choose your preferred payment option:",
        },

        settingsStudentProfilePage: {
            first_name: 'First Name',
            last_name: 'Last Name',
            buttonConfirm: 'Update',
            pageInfo: 'Public Profile',
            wallet_address: 'Wallet Address',
            firstNameMissing: "Please tell us your first name.",
            portuguese: "Portuguese",
            english: "English",
        },

        singUpForm: {
            nameMissing: "Please tell us your first and last name.",
            emailMissing: "Please provide a valid email.",
            passwordMissing: "Please provide a valid password.",
            dataAgreementMissing: "Please read and agree to our terms and conditions.",
        },

        authModal: {
            resetDescription: "To claim your NFT you just need to quickly set your password.",
            internalOfferDescription: "To reddem this offer follow the instructions below to create your Crowdclass account. If you already have a Crowdclass account please sign-in instead.",
            externalOfferDescription: "To reddem this offer sign up with your wallet. If you already have a Crowdclass account please sign-in instead.",
            nftDescription: "This NFT is issued using Crowdclass Platform. Sign-up to claim it and manage your collection there. If you already have a Crowdclass account please sign-in instead.",
            welcome: "Welcome to Crowdclass!",
            getStarted: "Get Started!",
            welcomeBack: "Welcome back!",
        },  

        offersLabels: {
            offerPageTitle: 'Offers',
            offerPageDescription: 'Here you can see all the offers you have available',
            offerAvailableLabel: 'Available',
            redeem: 'Redeem',
            offerRedeemedLabel: 'Redeemed',
            offerCardHeader: 'avaliable',
            offerCardLinkLabel: 'Read all the conditions of the offer at:',
            internalElegilibityLabelReward: 'You can redeem this offer {{dynamicText}} because you own:',
            internalElegilibityLabelCourse: 'You can redeem this offer because you own an NFT from the following collection:',
            externalEligibilityLabel: 'To reedeem this offer you need to own an NFT with the following eligible_attributes:',
            publicViewElegilibityLabelReward: 'To reedeem this offer you need to own the following NFT:',
            publicViewElegilibityLabelCourse:  'To reedeem this offer you need to own an NFT from the following collection:',
            contract: 'Contract:',
            offerCardElegilibityOnce: 'once',
            offerCardElegilibityTimes: 'times',
            offerCardOwningLabel: ' - Owning:',
            offerCardExpirationDate: 'Redeem by:',
            offerCardButton: 'Redeem Offer',
            offerCardLabelRedeemedState: 'Redeemed Offer',
            offerCardLabelRedeemedStateConfirmation: 'You will be contacted soon',
            offerRedeemedWith: "You redeemed this offer with:",
            noOffers: 'There are no offers available at this time',
            noOffersRedeemed: 'You have not redeemed any offers yet',
            notEligible: "You don't meet the criteria to redeem this offer. If you believe this to be a mistake please contact the organization who issued it.",
            reveal: "Reveal Content",
            description: "See description",
        },

        userOrders: {
            pageTitle: "Orders",
            pageDescription: "Here you can see and manage all your orders",
            completedOrders: "Completed",
            pendingOrders: "Pending",
            cancelledOrders: "Cancelled",
            noCompletedOrdersFound: "No completed orders found.",
            noPendingOrdersFound: "No pending orders found.",
            noCancelledOrdersFound: "No cancelled orders found.",
            referenceNotFound: "Reference no. not found",
            createdAt: "Created at",
            quantity: "Quantity",
            paid: "Paid",
            resendInvoiceButton: "Resend Invoice",
            invoiceSent: "Invoice Sent!",
            invoiceNotSent: "Invoice sending Error",
        },

        redeemModal: {
            firstLabel:'You are about to redeem one offer for {{title}} by {{course}}.',
            firstLabelExternal: 'You are about to redeem one offer for {{title}}.',
            secondLabel:'By redeeming it you will receive an e-mail with instructions on how to activate it in your contact address associated with Crowdclass.',
            thirdLabel:'Do you confirm you want to redeem the offer ?',
            redeemButtomConfirm: 'Yes!',
            redeemButtomCancel: 'No. I want to think about it a bit more.',
            redeemSuccess: "You redeemed your offer successfully.",
            onChainValidationError: "The digital collectible associated with this offer was claimed on-chain as an NFT. Unfortunately, on-chain NFT verification is not available yet but our team is working to launch it soon. We will get in touch with you as soon as it's ready, so you can redeem your offer."
        },

        reveal: {
            revealSoon: "reveal soon...",
        },

        pageInfo: {
            settings: "Settings",
            settingsDescription: "Settings page description",

            orders: "Orders",
            ordersDescritpion: "Orders page description",

            offers: "Offers",
            offersDescription: "Offers page description",

            reports: "Reports",
            reportsDescription: "Reports page description",

            community: "Community",
            communityDescription: "Community page description"
        },

        publishModal : {
            no: "No, continue editing",
            yes: "Yes, publish now",
            content: "After publishing this collection, <b>you will not be able to edit</b> the Surprise NFT Settings or any of the details of the collectibles themselves. Proceed?",
            title: "Ready to Publish?",
            totalSupply: "Total supply: ",
        },

        paymentProviderSelectionModal : {
            title: "Payment Options",
            paymentOption: "Please choose your preferred payment option:",
            cardButton: "Pay with card via",
            mbwayButton: "Pay with MBWAY",
            cryptoButton: "Pay with Crypto (via xMoney)",
            cardDescription: "Pay using your debit or credit card via",
            mbwayDescription: "Pay with MBWay.",
            utrustDescription: "You will be redirected to the xMoney payment widget compatible with any major crypto wallets. It will allow you to pay for your purchase in a safe and seamless way using Bitcoin, Ethereum, Tether or a number of other currencies.",
            utrustLink: "What is xMoney?",
        },
        
        organizationPage: {
            membershipsTitle: "Memberships",
            benefitsTitle: "Benefits",
            buyMembershipButtonText: "Buy",
            membershipTabTitle: "Choose your membership tier",
            membershipTabDescription: "Compare the different membership tiers and join this community now. Credit Card and Crypto payment available at checkout.",
            offerTabTitle: "Available Offers",
            offerTabDescription: "To redeem an offer all you need to do is to click the redeem button!",
            joinButtonText: "Join",
            subscriptionButtonText: "Subscribe",
            feedTitle: "Content",
            lockedPost: "This content is available to subscribers of the following tiers only:",
            seeMembershipTiersButton: "See Membership Tiers",
        },

        globalVariables: {
            limitedSupply: "Limited Supply",
            expirationDate: "Expiration Date",
            no: "No",
            yes: "Yes",
            clickHere: "Click Here",
            showAll: "Show All",
            filterBy: "Filter By",
            organization: "Organization",
            monthly: "per month",
            yearly: "per year",
            quarterly: "per quarter",
            lifetime: "one off payment",
        }
    }
};

export { labels }

