import React from 'react';
import PropTypes from 'prop-types';

const OrderCardImage = ({item, hideDetails}) => {
  return (
    <div className={"fullwidth-card-image-container"}>
        <img
            src={item.reward_image}
            className={`hidden fullwidth-card-image ${(item.reward_hide && hideDetails) && "blur"}`}
            alt={item.reward_title}
          />
      </div>
  )
};

OrderCardImage.propTypes = {
    item: PropTypes.object,
    hideDetails: PropTypes.bool,
};

export default OrderCardImage;