import React, { useState } from 'react';
import PropTypes from 'prop-types';

import "assets/css/paymentProviderEditModal.css"
import PaymentProviderForm from './PaymentProviderForm';
import PaymentProviderHelper from './PaymentProviderHelper';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';


const PaymentProviderEditModal = ({open, onClose, selectedProvider, organizationId, handleError}) => {

    const [helperText, setHelperText] = useState(null)

    return (
  
    <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={ onClose } className="payment-provider-edit-modal">
        
      <h3 className="payment-provider-edit-title">Editing: {selectedProvider.label}</h3>

      <DialogContent className="payment-provider-edit-content">
        <PaymentProviderForm selectedProvider={selectedProvider} 
                             onClose={onClose} 
                             onFocusChange={setHelperText} 
                             organizationId={organizationId} 
                             handleError={handleError}/>
        <PaymentProviderHelper helperText={helperText}/>
      </DialogContent>
    </Dialog>
  );
};

PaymentProviderEditModal.propTypes = {
    open: PropTypes.true,
    onClose: PropTypes.func,
    handleError: PropTypes.func,
    selectedProvider: PropTypes.object,
    organizationId: PropTypes.number
};

export default PaymentProviderEditModal;