import React from 'react'
import PropTypes from 'prop-types'

import FrequencySwitcher from 'containers/public/BuyActions/FrequencySwitcher';
import Price from 'containers/public/BuyActions/Price';

import { extractCurrency, extractPrice, extractPricingFrequencies } from 'utils/rewards';

import { Button } from '@mui/material';

import { t } from 'i18n/index';

const TierCardHeader = ({title, free, pricings, frequency, onFrequencyChange, onClick, already_issued_to, oneOffPayment }) => {
  return (
    <div className='fullwidth-card-header'>
        <h2>{ title }</h2>
        <div className='fullwidth-card-sub-header-container'>
          {free ? 
            <p className='zero fullwidth-card-price'>FREE</p>
            :
            <div className='fullwidth-card-price'>
              <Price price={ extractPrice(pricings, frequency) }
                    currency={ extractCurrency(pricings, frequency) }
                    />

              <FrequencySwitcher frequency={frequency} 
                                onFrequencyChange={onFrequencyChange} //setFrequency
                                frequencies={extractPricingFrequencies(pricings)}
                                />
            </div>
          }
          <Button variant="contained"
            color="warning"
            classes='MuiButton-outlinedWarning'
            className='fullwidth-card-button'
            onClick={ onClick } //handleRedirect
            disabled= { already_issued_to }>
              {free ? t('organizationPage:joinButtonText') : oneOffPayment ? t('organizationPage:subscriptionButtonText') : t('organizationPage:buyMembershipButtonText')}
          </Button>
        </div>
      </div>
  )
};

TierCardHeader.propTypes = {
    title: PropTypes.string,
    free: PropTypes.bool,
    pricings: PropTypes.array,
    frequency: PropTypes.string,
    onFrequencyChange: PropTypes.func,
    onClick: PropTypes.func,
    already_issued_to: PropTypes.bool,
    oneOffPayment: PropTypes.bool,
};

export default TierCardHeader;