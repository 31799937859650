import React from 'react'
import PropTypes from 'prop-types'
import Accordion from "components/Cards/OfferCard/OfferCardHeader/OfferCardAccordion";

const OfferCardHeader = ({item, textOfferInstance, offer, handleExternalOfferInstanceContent, preview}) => {
  return (
    <div className='fullwidth-card-header'>
        <Accordion title={item.title} 
                description={item.description}
                textOfferInstance={textOfferInstance}
                externalOfferInstance={!item.internal} 
                contentText={offer ? offer.content_text : item.content_text}
                contentTextLink={offer ? offer.content_text_link : item.content_text_link}
                onClick={ handleExternalOfferInstanceContent }
                preview={preview}
        />
    </div>
  )
}

OfferCardHeader.propTypes = {
    item: PropTypes.object,
    textOfferInstance: PropTypes.string,
    offer: PropTypes.object,
    handleExternalOfferInstanceContent: PropTypes.func,
    preview: PropTypes.bool,
};

export default OfferCardHeader;