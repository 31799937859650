import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/organization/OrganizationResource';

import NewOrganizationForm from '../Course/Wizard/NewOrganizationForm';
import { Button } from '@mui/material';


import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import { useController } from '@rest-hooks/react';

const OrganizationForm = ({record={}, handleError}) => {
  
  const { fetch } = useController();
  const { setOpen, setMessage, setSeverity } = useToast();

  const [organization, setOrganization] = useState(record);

  const submitOrganizationChanges = useCallback(
    async () => {
      try {
        const {organization_title, organization_external_link, image_file} = organization;
        const formData = new FormData();
        if (organization_title !== undefined && organization_title !== "") formData.append('title', organization_title)
        if (organization_external_link !== undefined) formData.append('external_link', organization_external_link)
        if (image_file) formData.append('image', image_file)
        const { id } = await fetch(OrganizationResource.update(), { id: organization.id }, formData );

        // success!
        if (id){
          fetch(OrganizationResource.list());
          customToast('success', `Organization Details Updated`, setOpen, setSeverity, setMessage);
        }
      }  catch (error) {
        handleError(error.message); 
      }
    },
    [fetch, organization],
  );
  return (
    <form id="admin-courses-add" onSubmit={ submitOrganizationChanges } >
          <NewOrganizationForm organization={organization}
                               onOrganizationChange={ setOrganization }
          />
          <div className={ `new-form-field` }>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </div>
        </form>
  )
}

OrganizationForm.propTypes = {
    record: PropTypes.object,
    handleError: PropTypes.func,
};

export default OrganizationForm;