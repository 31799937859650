import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { useSuspense } from "@rest-hooks/react";

import RewardResource from 'resources/organization/RewardResource';

import TextField from "components/formElements/TextField";
import SubmitButton from "components/buttons/SubmitButton";
import OutlinedButton from "components/buttons/OutlinedButton";
import UploadButton from "components/buttons/UploadButton";
import LoadingButton from "components/buttons/LoadingButton";
import AttachmentChip from "components/AttachmentChip";
import { rewardType } from "utils/constants";

import { Checkbox, FormControlLabel } from "@mui/material";
import VideocamIcon from '@mui/icons-material/Videocam';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import "assets/css/postForm.css";


const defaultPost = {
    body: '',
    reward_ids: [],
};

const PostForm = ({record={}, organizationId, onSubmit, loading }) => {

    const rewards = useSuspense(RewardResource.list(), {organization_id: organizationId});
    const passes = rewards.filter(item => item.category === rewardType.PASS.value)

    const [post, setPost] = useState({...defaultPost, ...record})
    
    const history = useHistory();

    const handleChange = ( { target: {name, value}} ) => {
        setPost({...post, [name]: value});
    };

    const handlePassToggle = (passId) => () => {
        const updatedRewardIds = post.reward_ids.includes(passId)
            ? post.reward_ids.filter(id => id !== passId)
            : [...post.reward_ids, passId];

        setPost({ ...post, reward_ids: updatedRewardIds });
    };

    const handleFileUpload = (event) => {
        if (!event.target.files) {
            return;
        }
        const files = Array.from(event.target.files);
        const type = event.target.name;
        let newPost = { ...post };

        if (!newPost[`${type}_files`]) {
        newPost[`${type}_files`] = [];
        }
        if (!newPost[`${type}_filenames`]) {
        newPost[`${type}_filenames`] = [];
        }
        files.forEach((file) => {
            newPost[`${type}_files`].unshift(file);
            newPost[`${type}_filenames`].unshift(file.name);
        });
        setPost(newPost);
    };

    const handleRemoveUpload = (type, index) => () => {
        let newPost = { ...post };
        newPost[`${type}_files`] = newPost[`${type}_files`].filter((_, i) => i !== index);
        newPost[`${type}_filenames`] = newPost[`${type}_filenames`].filter((_, i) => i !== index);
        setPost(newPost);
    };

    const hasAttachedImages = !!post.image_filenames && post.image_filenames.length > 0
    const hasAttachedVideos = !!post.video_filenames && post.video_filenames.length > 0

    return (
        <div className="post-form-container">
            <form onSubmit={(event) => onSubmit(event, post)} className="post-form-container-left">
                <TextField
                    name={ 'body' }
                    label={ post.id ? "Update Content" : "Add New Content" }
                    value={ post.body }
                    onChange={ handleChange }
                    multiline={true}
                    minRows={ 3 } 
                />
        
                <div className="post-form-attachments-list">
                    {hasAttachedImages &&
                        <AttachmentChip filenames={post.image_filenames} 
                                        type={"image"} 
                                        onDelete={handleRemoveUpload} />
                    }
                    {hasAttachedVideos &&
                        <AttachmentChip filenames={post.video_filenames} 
                                        type={"video"} 
                                        onDelete={handleRemoveUpload} />
                    }
                </div>

                <div className="post-form-buttons-container">
                    <div className="post-form-upload-buttons-container">
                        <UploadButton Icon={PhotoCameraIcon}
                                      inputId={"image-input"}
                                      name={"image"}
                                      onChange={handleFileUpload}
                                      multiple={true} />
                        <UploadButton Icon={VideocamIcon}
                                      inputId={"video-input"}
                                      name={"video"}
                                      onChange={handleFileUpload}
                                      multiple={true} />
                    </div>

                    {loading ?
                        <div className="post-form-loading-button">
                            <LoadingButton />
                        </div>
                        :
                        <div className="post-form-handle-buttons">
                            <SubmitButton description={post.id ? "Save Changes" : "Publish" }/>
                            <OutlinedButton description={"Cancel"} action={() => history.push(`/organizations/${organizationId}/content`)} fullWidth={true}/>
                        </div>
                    }
                </div>
            </form>
            
            <div className="post-form-container-right">
                <p>Pass holders of the following tiers will be able to see this content: </p>
                <div className="post-form-tiers-container" >
                    {passes.map(pass => (
                        <FormControlLabel
                            key={`pass-checkbox-${pass.id}`}
                            control={<Checkbox
                                checked={post.reward_ids && post.reward_ids.includes(pass.id)}
                                onChange={handlePassToggle(pass.id)}
                            />}
                            label={pass.title}
                        /> 
                    ))} 
                </div>
            </div>
        </div>
        
    )
};

PostForm.propTypes = {
    record: PropTypes.object,
    organizationId: PropTypes.number,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
};

export default PostForm;