import React from 'react'
import PropTypes from 'prop-types'
import 'assets/css/fullwidthCard.css';

const FullwidthCard = ({preview, image, header, content, footer}) => {

  return (
    <div className={`${preview  ? 'fullwidth-card-preview' : 'fullwidth-card'}`}>
      {image}
      {header}
      {content}
      {footer}
    </div>
  );
};

FullwidthCard.propTypes = {
  preview: PropTypes.bool,
  image: PropTypes.object,
  header: PropTypes.object,
  content: PropTypes.object,
  footer: PropTypes.object,
};

export default FullwidthCard;