import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import PaymentProvidersResource from 'resources/organization/PaymentProviderResource';

import TextField from 'components/formElements/TextField';
import SubmitButton from 'components/buttons/SubmitButton';
import OutlinedButton from 'components/buttons/OutlinedButton';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';

const PaymentProviderForm = ({selectedProvider, onClose, onFocusChange, organizationId, handleError}) => {
    
    const { fetch } = useController();
    const { setOpen, setMessage, setSeverity } = useToast();

    const transformedProviderData = selectedProvider.keys.reduce((acc, key) => {
        acc[key.value] = null;
        return acc;
    }, { provider: selectedProvider.value });

    const [values, setValues] = useState(transformedProviderData);
    
    const handleChange = (event, key) => {
        setValues({ ...values, [key]: event.target.value });
    };
    
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            const formData = new FormData();
            formData.append('id', organizationId);
            formData.append('provider_keys', JSON.stringify([{ ...values }]));
            const {id}  = await fetch(PaymentProvidersResource.create(), {organization_id: organizationId}, formData);
            if (id) {
                await fetch(PaymentProvidersResource.detail(), {organization_id: organizationId});
                customToast('success', `Payment Provider Setup Saved`, setOpen, setSeverity, setMessage);
            }
        } catch (error) {
            handleError(error);
        }
        onClose();
    };

    const handleTextFieldFocus = (key) => {
        onFocusChange(key.helper)
    };

  return (
    <form onSubmit={(event) => handleSubmit(event)} className="payment-provider-edit-form">
        {selectedProvider.keys.map((key, index) => (
            <TextField 
                key={index}
                label={ key.label }
                value={ values[key.value] }
                onChange={ (event) => handleChange(event, key.value) }
                onFocus={ () => handleTextFieldFocus(key) }
                autoFocus={ index === 0 }
                type="text"
                />
        ))}
        <div className="payment-provider-edit-form-buttons">
            <SubmitButton description={"Save Keys"} />
            <OutlinedButton description={"Cancel"} action={onClose} fullWidth={true}/>
        </div>
    </form>
  );
};

PaymentProviderForm.propTypes = {
    onFocusChange: PropTypes.func,
    onClose: PropTypes.func,
    handleError: PropTypes.func,
    selectedProvider: PropTypes.object,
    organizationId: PropTypes.number
};

export default PaymentProviderForm;