import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAccount, useSignMessage } from 'wagmi';
import { verifyMessage } from 'ethers/lib/utils';
import { useController } from '@rest-hooks/react';
import Button from '@mui/material/Button';
import AuthChallengeResource from 'resources/auth/ChallengeResource';
import { t } from 'i18n/index';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import StatusModal from 'components/StatusModal';

import '@rainbow-me/rainbowkit/styles.css';

const challengeTypeButton = {
  'signup': t('studentProfile:collectorsPortal_Connect'),
  'login': t('studentProfile:collectorsPortal_Connect'),
  'connect': t('studentProfile:collectorsPortal_Connect'),
  'redeem': t('offersLabels:redeem'),
  'reveal': t('offersLabels:reveal'),
}

const ConnectWalletButton = ({challengeType, done }) => {
  const { address, isConnected } = useAccount();
  const { fetch } = useController();
  const [error, setErrorMessage] = useState(null);

  const { signMessage } = useSignMessage({
    onSuccess(data, variables) {
      const wallet_address = verifyMessage(variables.message, data);
      if (wallet_address !== address) {
        throw new Error('Invalid signature');
      }
      done({ wallet_address, challenge: variables.message, signature: data });
    },
  })

  useEffect(() => {
    if (isConnected) {
      signChallenge(address);
    }
  }, [isConnected]);

  const signChallenge = async (wallet) => {
    try {
      const { challenge } = await fetch(AuthChallengeResource.create(), {wallet_address: wallet, challenge_type: challengeType});
      signMessage({ message: challenge });
      return challenge;
    } catch (e) {
      handleError(e);
    }
  }

  const handleConnect = () => {
    signChallenge(address);
  }

  const handleError = async (error) => {
    const { errors } = await error.response.json();
    const errorMessage = errors ? errors.base[0] : error.message;
    setErrorMessage(errorMessage);
  };

  const isBlack = challengeType !== 'connect';

  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading';
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus ||
              authenticationStatus === 'authenticated');

          return (
            <div
              {...(!ready && {
                'aria-hidden': true,
                'style': {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Button fullWidth={ isBlack }
                            variant={ !isBlack ? "contained" : undefined }
                            sx={ isBlack ? { bgcolor: '#000', color:'#fff !important'} : {} }
                            onClick={ openConnectModal }
                    >
                      { challengeTypeButton[challengeType] }
                    </Button>
                  );
                }

                return (
                  <div style={{ display: 'flex', gap: 12 }}>
                    <Button fullWidth={ isBlack }
                            variant={ !isBlack ? "contained" : undefined }
                            sx={ isBlack ? { bgcolor: '#000', color:'#fff !important'} : {} }
                            onClick={ handleConnect }
                    >
                      { challengeTypeButton[challengeType] }
                    </Button>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>

      { error && <StatusModal message={ error } open ={ error } onClose={ () => setErrorMessage(null) }/>}
    </>
  )
}

ConnectWalletButton.propTypes = {
  challengeType: PropTypes.string,
  done: PropTypes.func
};

export default ConnectWalletButton;
