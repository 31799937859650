import React from 'react'
import PropTypes from 'prop-types'

import ConnectWalletButton from 'components/authForms/WalletConnect/ConnectWalletButton';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { t } from 'i18n/index';

const CustomAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} {...props} className='offer-card-background-color-theme'/>
        ))(() => ({
            '&:not(:last-child)': { borderBottom: 0 },
            '&:before': { display: 'none' },
    }));

const OfferCardAccordion = ({ description, title, textOfferInstance, externalOfferInstance, contentText, contentTextLink, onClick, preview }) => {

    return (     
        <CustomAccordion >
            <AccordionSummary sx={{padding: "0 !important"}}>
                <div>
                    <h2>{title}</h2>
                    <div>{textOfferInstance ? 
                            externalOfferInstance ?
                                <ConnectWalletButton challengeType='reveal' done={ onClick } /> :
                                <Button variant="contained"
                                    classes='MuiButton-outlinedWarning'>
                                    {t('offersLabels:reveal')}
                                </Button> :
                            <p>{t('offersLabels:description')}</p>}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={{padding: "0 !important"}}>
                <ReactMarkdown>{ textOfferInstance && externalOfferInstance && !contentText ? 
                                    "Please authenticate this request to reveal content" : 
                                        contentText && !preview ? 
                                            contentText : 
                                                description }
                </ReactMarkdown>
                {(contentTextLink && !preview) &&
                    <Button variant="outlined"
                                color="warning"
                                classes='MuiButton-outlinedWarning'
                                onClick={ () => window.location.replace(contentTextLink) }>
                            {t('merch:redirectButton')}
                    </Button>
                }
            </AccordionDetails>
        </CustomAccordion> 
        
    )
};

OfferCardAccordion.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    textOfferInstance: PropTypes.bool,
    externalOfferInstance: PropTypes.bool,
    contentText: PropTypes.string,
    contentTextLink: PropTypes.string,
    onClick: PropTypes.func,
    preview: PropTypes.bool,
};

export default OfferCardAccordion;