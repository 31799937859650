import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material';

const StandardButton = ({action, description, fullWidth}) => {
  return (
    <Button fullWidth={fullWidth}
            variant="contained"
            color="warning"
            onClick={action}
    >
        {description}
    </Button>
  )
};

StandardButton.propTypes = {
    action: PropTypes.func,
    description: PropTypes.string,
    fullWidth: PropTypes.bool,
};

export default StandardButton;