import React from "react";
import PropTypes from 'prop-types'

import TextField from 'components/formElements/TextField';

const Title = ({offer,
                onChange,
                isDisabled
              }) => {
  return (
    <TextField name={ 'title' } 
               label={ 'Offer Title *' } 
               value={ offer.title } 
               onChange={ onChange } 
               disabled={ isDisabled } 
    />
  );
};

Title.propTypes = { 
  offer: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};
  
export default Title;