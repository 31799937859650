import React from 'react';
import PropTypes from 'prop-types';

import TierCard from 'components/Cards/TierCard';

const TiersGrid = ({ data }) => {

    return (
     <div>
      { data.map( ({id, title, description, media_type, image_url, claim_token, video_url, pricings, already_issued_to}) => (
          <TierCard key={ id }
                     id={ id }
                     title={ title }
                     description={ description }
                     media_type={ media_type }
                     image_url={ image_url }
                     claim_token = { claim_token }
                     video_url={ video_url }
                     pricings={ pricings }
                     already_issued_to={ already_issued_to }
          />
        ))
      }
    </div>   
    )
  };
 
  TiersGrid.propTypes = {
    data: PropTypes.array,
  };
  
  export default TiersGrid;