import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material';

const SubmitButton = ({description}) => {
  return (
    <Button fullWidth
            type="submit"
            variant="contained"
            color="primary">
        {description}
    </Button>
  )
};

SubmitButton.propTypes = {
    action: PropTypes.func,
    description: PropTypes.string,
};

export default SubmitButton;