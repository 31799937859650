import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class TierResource extends AuthenticatedResource {
  static urlRoot = '/passes' as const;

  readonly id: number | undefined = undefined;
  readonly organization_title: string = '';
  readonly title: string = '';
  readonly media_type: string = '';
  readonly image_url: string = '';
  readonly video_url: string = '';
  readonly claim_token: string = "";

  pk() {
    return this.id?.toString();
  }
}
