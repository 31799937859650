import React from 'react';
import PropTypes from 'prop-types';

import formattedDate from 'utils/formattedDate';
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';

import { t } from 'i18n/index';

const OrderCardContent = ({item}) => {
  return (
    <div className='fullwidth-card-content'>
        <p>{t('userOrders:createdAt')}: {formattedDate(item.created_at)}</p>
        <p>{t('userOrders:quantity')}: {item.amount}</p>
        <p>{t('userOrders:paid')}: {convertPriceFromCents(item.total_price)} {currencySymbol[item.currency]}</p>
    </div>
  )
}

OrderCardContent.propTypes = {
    item: PropTypes.object,
};

export default OrderCardContent;