import React, { useEffect, useRef } from 'react'
import { useSuspense } from '@rest-hooks/react';
import PropTypes from 'prop-types'

import TiersGrid from 'components/CardGrids/TiersGrid';
import TierResource from 'resources/user/TierResource';
import { useLogout } from 'utils/useLogout';
import UserName from 'utils/localStorage/UserName';

import { Link } from '@mui/material';
import { t } from 'i18n/index';
import { useHistory } from 'react-router-dom';

const OffersTabContent = ({ organizationId }) => {

    const tiers = useSuspense(TierResource.list(), {organization_id: organizationId});
  
    const logout = useLogout();
    const redirect = false;
    const userName = UserName.read();

    const topRef = useRef(null);
    const history = useHistory();

    useEffect(() => {
        history.replace({ search: '' });
        topRef.current.scrollIntoView();
    }, [])
    
  return (
    <div>
        <div ref={topRef} style={{ position: 'absolute', top: 0 }} />

        <div className="header">
            <h1 className='title-color-theme'>{t('organizationPage:membershipTabTitle')}</h1>
            <p>{t('organizationPage:membershipTabDescription')}</p>
            { userName && 
            <p>
                {t('publicClaimPage:currentlyLoggedIn')}&nbsp;
                <b>{ userName }</b>.&nbsp;
                <Link underline="hover" color="inherit" onClick={ () => logout(redirect) }>{t('publicClaimPage:logOut')}</Link>
            </p>
            }
        </div>

        <TiersGrid data={tiers} />

    </div>
    
  )
};

OffersTabContent.propTypes = {
    organizationId: PropTypes.number
};

export default OffersTabContent;