import React from 'react';
import PropTypes from 'prop-types';
import OfferCard from 'components/Cards/OfferCard';

const OfferPreview = ({ item, preview, image }) => {

  return (
    <OfferCard item={ item } preview={preview} image={image} />
  );
};

OfferPreview.propTypes = {
    item: PropTypes.object,
    preview: PropTypes.bool,
    image: PropTypes.object,
};

export default OfferPreview;
