import React  from 'react';
import { useSuspense } from '@rest-hooks/react';

import OrdersGrid from 'components/CardGrids/OrdersGrid';
import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';

import OrderResource from 'resources/user/OrderResource';

import { t } from 'i18n/index';

const pageInfo = {
    name: t('userOrders:pageTitle'),
    description: t('userOrders:pageDescription'),
  };

const Orders = () => {

    const orders = useSuspense(OrderResource.list());
    const completedOrders = orders.filter( order => order.status === "completed" )
    const pendingOrders = orders.filter( order => order.status === "pending" )
    const cancelledOrders = orders.filter( order => order.status === "cancelled" )

    const labels = [ `${t('userOrders:completedOrders')}`]
    pendingOrders.length > 0 && labels.push(t('userOrders:pendingOrders'))
    cancelledOrders.length > 0 && labels.push(t('userOrders:cancelledOrders'))

    return (
        <Layout context='student'
                activeMenuItem='collectibles'
                pageInfo={ pageInfo } >

            <TabPanels labels={labels}>

                    {completedOrders.length > 0 ?
                        <OrdersGrid data={ completedOrders } hideDetails={ false }/> :
                        <p>{t('userOrders:noCompletedOrdersFound')}</p>
                    }
                    <OrdersGrid data={ pendingOrders } hideDetails={ true }/>

                    <OrdersGrid data={ cancelledOrders } hideDetails={ true }/>

            </TabPanels>
        </Layout>
    );
};

export default Orders;
