import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import OrderResource from 'resources/user/OrderResource';

import { Button } from '@mui/material';

import { t } from 'i18n/index';

const OrderCardHeader = ({item}) => {

  const [message, setMessage] = useState(t('userOrders:resendInvoiceButton'))
  const {fetch} = useController();

  const handleResendInvoice = async () => {
    try {
        await fetch(OrderResource.detail(), {id: item.id});
        setMessage(t('userOrders:invoiceSent'))
    } catch (error) {
      setMessage(t('userOrders:invoiceNotSent'))
    }
  };

  const isInvoiceReady = true;

  return (
    <div className='fullwidth-card-header'>
        <h2>{item.reward_title}</h2>
        <div className='fullwidth-card-sub-header-container'>
          <p>{item.reference ? `# ${item.reference}` : t('userOrders:referenceNotFound')}</p>
          {isInvoiceReady &&
          <Button variant="contained" 
                onClick={ handleResendInvoice }
                classes='MuiButton-outlinedWarning'
                className='fullwidth-card-button'
                disabled={message === t('userOrders:invoiceSent') || message === t('userOrders:invoiceNotSent') }
                >
                {message}
          </Button>}
        </div>
    </div>
  )
};

OrderCardHeader.propTypes = {
    item: PropTypes.object,
};

export default OrderCardHeader;