import React from 'react';
import PropTypes from 'prop-types';

const AttachedVideo = ({videoUrl}) => {
  return (
    <video 
        className='post-card-attached-video'
        controls>
        <source src={videoUrl} type="video/mp4" />
    </video>
  )
}

AttachedVideo.propTypes = {
    videoUrl: PropTypes.string,
};

export default AttachedVideo;