import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import Media from './Media';
import Description from './Description';

import 'assets/css/preview.css';
import { convertPriceFromCents } from 'utils/rewards';

const PassPreview = ({ pricings, id, title, description, reward_type, media_type, image_url, image, video, organizationLogo,  claim_state, badge_tx, showOnChainStatus, showDownloadButtons }) => {

  return (
    <div id="reward-preview">
      <Title title={ title }
             showOnChainStatus={ showOnChainStatus }
             id={ id }
             claim_state={ claim_state }
             badge_tx={ badge_tx }
      />

      <Media id={ id }
             image={ image }
             image_url={ image_url }
             media_type={ media_type }
             title={ title }
             reward_type={ reward_type }
             video_url={ video }
             showDetails={ true }
             showDownloadButtons={ showDownloadButtons }
             organizationLogo={ organizationLogo }
      />


      <div className="pricing-container">
        <h2>Pricing</h2>
          { pricings && pricings.length > 0 ?
            pricings.map( ({price, currency, frequency}, index) => (
              <div key={ index }> { convertPriceFromCents(price) } { currency } / { frequency } </div>
            ))
            : 'FREE'
          }
      </div>

      <Description description={ description } />

    </div>
  );
};

PassPreview.propTypes = {
  pricings: PropTypes.array,
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  reward_type: PropTypes.string,
  organization: PropTypes.string,
  activity_title: PropTypes.string,
  activity_link: PropTypes.string,
  activity_type: PropTypes.string,
  supply_limit: PropTypes.number,
  end_date: PropTypes.string,

  metadata: PropTypes.array,
  media_type: PropTypes.string,
  image_url: PropTypes.string,
  image: PropTypes.object,
  video: PropTypes.string,
  organizationLogo: PropTypes.string,

  actions: PropTypes.node,

  claim_state: PropTypes.string,
  badge_tx: PropTypes.string,

  created_at: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  claimed_at: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  isWhitelabel: PropTypes.bool,
  showDetails: PropTypes.bool,
  showOnChainStatus: PropTypes.bool,
  showDownloadButtons: PropTypes.bool,
};

export default PassPreview;
