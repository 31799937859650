import moment from 'moment';
import { courseType } from './constants';

/*REWARD HELPERS*/
export const currencySymbol = {"EUR" : "€", "USD" : "$", "GBP" : "£"};

export const extractPrice = (pricings, priceFrequency) => {
    return priceFrequency ? pricings.find(element => element.frequency === priceFrequency ).price : pricings[0].price
  };

export const extractCurrency = (pricings, priceFrequency) => {
    return priceFrequency ? pricings.find(element => element.frequency === priceFrequency ).currency : pricings[0].currency
  };

export const extractPricingFrequencies = (pricings) => {
    return pricings.map(pricing => {return {frequency: pricing.frequency, id: pricing.id}})
  };

export const extractPricingId = (frequency, pricings) => {
    return pricings.find(pricing => pricing.frequency === frequency).id
  };

export  const convertPriceFromCents = (price) => {
    if (!price) {
      return null;
    }

    const convertedPrice = price / 100;
    const decimalPart = convertedPrice % 1;

    if (decimalPart === 0) {
      return convertedPrice.toFixed(0); // Display as an integer
    } else {
      return convertedPrice.toFixed(2); // Display with two decimal places
    }
  };

export const convertPriceToCents = (price) => {
    if (!price) {
      return null;
    }

    return price * 100;
  };

export function priceIndexFromPricings(pricings) {
  if (pricings == null) { return {}; }

  const index = {};
  pricings.forEach( ({frequency, price}) => {
    index[frequency] = convertPriceFromCents(price);
  });
  return index;
}

export function pricingsFromPriceIndex(prices, currency) {
  return Object.entries(prices).map( ([frequency, price]) =>
            ( price !== "" ? { frequency, price: convertPriceToCents(price), currency: currency } : null )
          ).filter( (p) => p != null);
}

export const isRewardUnavailable = (reward) => {
    const atSupplyLimit = reward.supply_limit !== null && reward.supply_limit <= reward.stats["issued"];
    const expiredDate = reward.end_date !== null && moment(reward.end_date) < moment(Date.now());
    return !reward.active_state || atSupplyLimit || expiredDate;
  };

export const getMaxAmount = (reward, issued_count) => {
  if (reward.course_category === courseType.MYSTERYBOX.value) {
    return 1
  } else {
    return Math.min(reward.supply_limit ? reward.supply_limit - reward.stats.issued
      : Infinity, reward.claim_limit - issued_count)
  }
};
