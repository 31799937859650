import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { rewardType } from 'utils/constants';
import Button from '@mui/material/Button';
import { convertPriceFromCents } from 'utils/rewards';

const Stats = ({id, organizationId, courseId, category, unavailable, active_state, supply_limit, stats, showSendButton, pricings=[]}) => {
  const isMembership = category === rewardType.PASS.value;
  const isFree =pricings.length === 0;
  const { price, currency, frequency } = pricings[0] || {};

  return <div className="badge-stats grid">
    {showSendButton && !isMembership &&
      <div className="button-wrapper">
        <Button component={Link} to={ `/organizations/${organizationId}/collections/${courseId}/collectible/${id}` } variant="contained" color="primary" disabled={unavailable}>
          {active_state ? 'Send' : 'Inactive'}
        </Button>
      </div>}

    {(!showSendButton || isMembership) &&
      <>
        <div className="stats">
          <p className="stat-number">{supply_limit ? `${stats['issued']} / ${supply_limit}` : stats['issued']}</p>
          <p className="stat-title">
            {isMembership ?
              'SUBSCRIPTIONS' :
              `COLLECTED`
            }
          </p>
        </div>

        { !isMembership &&
          <div className="stats">
            <p className="stat-number">{stats['claimed']}</p>
            <p className="stat-title">{rewardType[category.toUpperCase()].label}{stats['claimed'] > 1 && 'S'} CLAIMED</p>
          </div>
        }

        { isMembership &&
          <div className="stats">
              <p className="stat-number">{isFree ? '0' : `${convertPriceFromCents(price)} ${currency}`}</p>
              <p className="stat-title">{isFree ? 'FREE' : frequency}</p>
          </div>
        }
      </>}
  </div>;
};

Stats.propTypes = {
  id: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  courseId: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  unavailable: PropTypes.bool.isRequired,
  active_state: PropTypes.bool.isRequired,
  supply_limit: PropTypes.number.isRequired,
  stats: PropTypes.object.isRequired,
  showSendButton: PropTypes.bool.isRequired,
  pricings: PropTypes.array.isRequired,
};

export default Stats;
