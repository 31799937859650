import React from 'react'
import { useSuspense } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'

import OffersGrid from 'components/CardGrids/OffersGrid';
import RedeemResource from 'resources/public/RedeemResource';
import { useLogout } from 'utils/useLogout';
import UserName from 'utils/localStorage/UserName';

import { Link } from '@mui/material';
import { t } from 'i18n/index';

const OffersTabContent = ({ organizationId }) => {

    const offers = useSuspense(RedeemResource.list(), {organization_id: organizationId});

    const history = useHistory();

    const handleRedirect = () => {
      history.push(`/offers?defaultIndex=1`)
    };
  
    const logout = useLogout();
    const redirect = false;
    const userName = UserName.read();
    const organizationTitle = offers.length > 0 && offers[0].organization_title;

  return (
    <div>
        <div className="header">
            <h1 className='title-color-theme'>{ t('organizationPage:offerTabTitle') }</h1>
            <p>{organizationTitle ? t('organizationPage:offerTabDescription') : t('offersLabels:noOffers')}</p>
            { userName && 
            <p>
                {t('publicClaimPage:currentlyLoggedIn')}&nbsp;
                <b>{ userName }</b>.&nbsp;
                <Link underline="hover" color="inherit" onClick={ () => logout(redirect) }>{t('publicClaimPage:logOut')}</Link>
            </p>
            }
        </div>

        <OffersGrid data={offers}
                    onRedeem={handleRedirect}
                    publicView={ true }
                    />
    </div>
    
  )
};

OffersTabContent.propTypes = {
    organizationId: PropTypes.number
};

export default OffersTabContent;