import React, { useCallback, useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";
import CourseResource from 'resources/organization/CourseResource';
import { capitalize } from 'utils/capitalize';

import RewardResource from 'resources/organization/RewardResource';

import Layout from 'components/layouts/index';
import Form from './form'
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import StatusModal from 'components/StatusModal';

import { courseType, rewardType } from 'utils/constants';
import OrganizationResource from 'resources/organization/OrganizationResource';

/* eslint-disable react/prop-types */
const NewReward = ({membership=false}) => {

  const { fetch } = useController();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false)

  const params = useParams();
  const courseId = parseInt(params.courseId);
  const organizationId = parseInt(params.organizationId);

  const searchParams = new URLSearchParams(window.location.search);
  const mysteryBoxReward = searchParams.has('mysteryBox') && searchParams.get('mysteryBox') === 'true';

  const title = membership ?
                `Create new membership pass` :
                mysteryBoxReward ?
                `Create your collection's mystery box settings` :
                `Create new credential`;

  const pageInfo = {
    name: title
  };

  const course = useSuspense(CourseResource.detail(), {id: courseId, organization_id: organizationId});
  const mysteryBox = course.category === courseType.MYSTERYBOX.value;

  const { permissions } = useSuspense(OrganizationResource.detail(), {id: organizationId});

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setLoading(false);
    setOpenErrorModal(false);
  };

  const handleValidation = (reward) => {
    validateTitle(reward);
    validateMedia(reward);
    if (!mysteryBox) {
      validatePublicLink(reward);
    }
    if (mysteryBox) {
      validateSupplyLimit(reward);
    }
    validateProperties(reward.metadata);
  };

  const validateTitle = ({ title }) => {
    if (title === ""){
      throwError("Please provide a title.");
    }
  };

  const validateMedia = ({ media_type, image_file, video_file}) => {
    if (!image_file && media_type === "image") {
      throwError("Please upload an image.");
    } else if (!image_file || !video_file && media_type === "video"){
      throwError("Please upload an image and video.");
    }
  };

  const validatePublicLink = ({ category, public: isPublic }) => {
    if (isPublic === '') {
      throwError(`Please set your ${capitalize(rewardType[category.toUpperCase()].label)} claiming link as public or private.`);
    }
  };

  const validateSupplyLimit = ({ supply_limit, category}) => {
    if (!supply_limit || supply_limit < 1) {
      throwError(`Please set your ${capitalize(rewardType[category.toUpperCase()].label)} supply limit.`);
    }
  };

  const validateProperties = (properties) => {
    if (properties.filter( ({key, value}) => key && !value || !key && value).length > 0 ){
      throwError("Please assign a property key to each value and vice-versa.");
    }
  };

  const throwError = (errorMessage) => {
    setError(errorMessage);
    handleOpenErrorModal();
    throw '';
  };

  const { setOpen, setMessage, setSeverity } = useToast();

  const createFormData = (reward, templateVars) => {
    const { title, description, category, template, public: isPublic, active_state, image_file, video_file,
            end_date, supply_limit, claim_limit, media_type, hide_details, pricings, metadata } = reward;

    const formData = new FormData();
    const fields = [
      { key: 'title', value: title },
      { key: 'description', value: description },
      { key: 'category', value: mysteryBoxReward ? 'mysteryBox' : category },
      { key: 'template', value: template },
      { key: 'public', value: mysteryBox ? true : isPublic },
      { key: 'active_state', value: active_state },
      { key: 'end_date', value: end_date },
      { key: 'supply_limit', value: supply_limit },
      { key: 'template_config', value: JSON.stringify(templateVars) },
      { key: 'claim_limit', value: claim_limit > 0  ? claim_limit : 1 },
      { key: 'media_type', value: media_type },
      { key: 'hide_details', value: hide_details }
    ];

    if (image_file) {
      fields.push({ key: 'image', value: image_file });
    }

    if (video_file){
      fields.push({ key: 'video', value: video_file });
    }

    fields.forEach(field => {
      formData.append(field.key, field.value);
    });

    pricings.forEach( pricing => {
      formData.append('pricing_config[]', JSON.stringify(pricing));
    });

    metadata.filter( ({key, value}) => key && value) // remove last one or empty rewards
              .forEach( property => {
                formData.append('metadata[]', JSON.stringify(property));
              });

    return formData;
};

  const handleOnSubmit = useCallback(
    async (e, reward, templateVars) => {
      e.preventDefault();
      setLoading(true);
      handleValidation(reward);
      try {
        const formData = createFormData(reward, templateVars);
        const {id, course_id, organization_id} = await fetch(RewardResource.create(), {course_id: courseId, organization_id: organizationId}, formData);
        // success!
        if (id){
          fetch(RewardResource.list(), {course_id: courseId, organization_id: organizationId});
          customToast('success', `New ${rewardType[reward.category.toUpperCase()].label} created`, setOpen, setSeverity, setMessage);
          const collectionType = membership ? 'memberships' : 'collections';
          history.push(`/organizations/${organization_id}/${collectionType}/${course_id}`);
        }
      }  catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form onSubmit={ handleOnSubmit } course={ course } loading={ loading } mysteryBoxReward={ mysteryBoxReward } permissions= { permissions }/>
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
  )
};


export default NewReward;
