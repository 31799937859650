import React, { useState } from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import OrganizationResource from 'resources/organization/OrganizationResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import StatusModal from 'components/StatusModal';
import WhitelabelFallback from 'components/fallbacks/Whitelabel';
import SalesFallback from 'components/fallbacks/Sales';
import WithPermission from 'components/WithPermission';

import WhitelabelForm from './WhitelabelForm';

import { t } from 'i18n/index';
import OrganizationForm from './OrganizationForm';
import MembersForm from './MembersForm';
import PaymentProvidersForm from './PaymentProviders';

const pageInfo = {
  name: t('pageInfo:settings'),
  description: t('pageInfo:settingsDescription'),
};

const Settings = () => {

  const params = useParams();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const organizationId = parseInt(params.organizationId);

  const savedOrganization = useSuspense(OrganizationResource.detail(), {id: organizationId});

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleError = (message) => {
    setError(message);
    handleOpenErrorModal();
  };

  return (
    <Layout context='teacher'
            activeMenuItem='settings'
            pageInfo={pageInfo}
            >

      <TabPanels labels={ [`Details`, `Members`, `Theme`, `Payment Providers`] } >
        <OrganizationForm record={savedOrganization}
                          handleError={handleError}
        />
        <MembersForm managers={savedOrganization.managers}
                     organizationId={organizationId}
                     handleError={handleError}
        />
        <WithPermission permission={'hasWhitelabel'} fallback={<WhitelabelFallback />}>
          <WhitelabelForm organizationId={organizationId}
                          handleError={handleError}
        />
        </WithPermission>
        <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>
          <PaymentProvidersForm organizationId={organizationId} />
        </WithPermission>
      </TabPanels>
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
)};

export default Settings;
