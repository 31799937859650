import React from 'react';
import PropTypes from 'prop-types';
import { useSuspense } from '@rest-hooks/react';

import CourseResource from 'resources/admin/CourseResource';
import TabPanels from 'components/TabPanels';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { coursesColumns } from 'utils/columns/admin/coursesColumns';

const pageInfo = {
  label: "Admin",
  name: "Courses"
};

const AdminCourses = () => {

  const courses = useSuspense(CourseResource.list());

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='collectibles'
            statOverview={
              <StatCard type='number'
                        highlight={ courses.length }
                        title='Courses'
              />
            } >

      <TabPanels labels={[ "Courses" ]} >
        <DataSelectionTable records={courses}
                              columns={coursesColumns}
                              checkboxSelectRows={false}
        />
      </TabPanels>

    </Layout>
)};


AdminCourses.propTypes = {
  history: PropTypes.object,
}

export default AdminCourses;
