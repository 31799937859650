import React from 'react';
import PropTypes from 'prop-types';

import { rewardType, courseType } from 'utils/constants';
import { capitalize } from 'utils/capitalize';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const LinkSettings = ({reward, course, mysteryBoxReward, published, isDisabled, onChange}) => {
  if (course.category ===courseType.MEMBERSHIP.value) {
    return null;
  }

  const mysteryBox = course.category === courseType.MYSTERYBOX.value;

  const handleDateTimeChange = (value) => {
    onChange({target: {name: "end_date", value }});
  };

  return <>
    <h3 className="new-form-section">Claim Link Settings</h3>

    {!mysteryBox &&
      <div className={`new-form-field`}>
        <FormControl fullWidth>
          <InputLabel id="reward-category-label" color="warning">QR Code / Link settings *</InputLabel>
          <Select
            labelId="reward-category-label"
            label="QR Code / Link settings"
            name="public"
            color="warning"
            value={reward.public}
            onChange={onChange}
            disabled={isDisabled}
          >
            <MenuItem value={true}> {`Public - Anyone with the link can claim the ${capitalize(rewardType[reward.category.toUpperCase()].label)} `}</MenuItem>
            <MenuItem value={false}>{`Private - Only users in the contact Whitelist can claim the ${capitalize(rewardType[reward.category.toUpperCase()].label)} `}</MenuItem>
          </Select>
        </FormControl>
      </div>}
    <div className={`new-form-field`}>
      <TextField
        fullWidth
        name="supply_limit"
        label={`Total ${mysteryBoxReward ? "collection" : ""} supply${mysteryBox ? " *" : ". Leave empty for unlimited supply"}`}
        color="warning"
        value={reward.supply_limit}
        type="number"
        onChange={onChange}
        disabled={published} />
    </div>
    {(!mysteryBox || mysteryBoxReward) &&
      <>
        <div className={`new-form-field`}>
          <TextField
            fullWidth
            name="claim_limit"
            label={`Max amount per user. Leave empty for 1`}
            color="warning"
            value={reward.claim_limit}
            type="number"
            onChange={onChange}
            disabled={published} />
        </div>
        <div className={`new-form-field`}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker label="Expiration date. Leave empty for no expiration."
              value={reward.end_date}
              onChange={handleDateTimeChange}
              minDate={moment().toDate()}
              renderInput={(params) => <TextField {...params} color="warning" fullWidth />}
              disabled={published} />
          </LocalizationProvider>
        </div>
      </>}
    {!mysteryBox &&
      <div className={`new-form-field`}>
        <Box>
          <FormControlLabel
            label={`Hide Details in Claim Page`}
            control={<Checkbox
              name="hide_details"
              checked={reward.hide_details}
              onChange={onChange} />} />
        </Box>
      </div>}
  </>;
};

LinkSettings.propTypes = {
  reward: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  mysteryBoxReward: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LinkSettings;
