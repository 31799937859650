import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'i18n/index';
import { offerType } from 'utils/offersFormHelper';

const OfferCardRewards = ({ publicView, item }) => {

    const {eligible_rewards, redeem_state, offer_type, contract_address, eligible_attributes, reward_title, course_title, reward_instance_title} = item;
    
    const ownedEligibleRewardsAmount = eligible_rewards ? eligible_rewards.length : 0;
    const dynamicText =
        ownedEligibleRewardsAmount === 1
        ? t('offersLabels:offerCardElegilibityOnce')
        : `${ownedEligibleRewardsAmount} ${t('offersLabels:offerCardElegilibityTimes')}`;
    
    const showRedeemCriteriaOnly = publicView && !eligible_rewards || publicView && eligible_rewards.length === 0;

    return (
    <div>
        {!redeem_state ? //determines if it is an instance or not
            <div>
                {offer_type === offerType.external ?
                    <div> 
                        <p>{t('offersLabels:externalEligibilityLabel', {dynamicText})}</p>
                        <ul>
                            <li className='break'>{t('offersLabels:contract', {dynamicText})} {contract_address}</li>
                            {eligible_attributes && eligible_attributes.map( (attribute) => (
                            <li key={attribute['trait_type']}>
                                {attribute['trait_type']}: {attribute['value']}
                            </li>
                            ))}
                        </ul>
                    </div>
                : 
                    showRedeemCriteriaOnly ?
                        <div>
                            { reward_title ?
                                <div>
                                    <p>{t('offersLabels:publicViewElegilibityLabelReward')}</p>
                                    <ul>
                                        <li>{reward_title}</li>
                                    </ul>
                                </div>
                                :
                                <div>
                                    <p>{t('offersLabels:publicViewElegilibityLabelCourse')}</p>
                                    <ul>
                                        <li>{course_title}</li>
                                    </ul>
                                </div>
                            }
                        </div>
                    :   
                        <div>
                            {reward_title ?
                                <div> 
                                    <p>{t('offersLabels:internalElegilibityLabelReward', {dynamicText})}</p>
                                    <ul>{eligible_rewards.map((reward, i) => (
                                        <li key={i}>
                                                {reward.title}
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                                :
                                <div> 
                                    <p>{t('offersLabels:internalElegilibityLabelReward', {dynamicText})}</p>
                                    <ul>{eligible_rewards.map((reward, i) => (
                                        <li key={i}>
                                                {reward.title}
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            }                   
                        </div>
                    }
                </div>
            :
                <div className='offer-card-rewards'>
                    <p>{t('offersLabels:offerRedeemedWith')}</p>
                    <ul>
                        <li >
                            {reward_instance_title}
                        </li>
                    </ul>
                </div>
            }
        </div>

    )
};

OfferCardRewards.propTypes = {
    publicView: PropTypes.bool,
    item: PropTypes.object,
};

export default OfferCardRewards;
