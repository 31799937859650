import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import ThemeResource from 'resources/public/ThemeResource';

import PublicLayout from 'components/layouts/Public';
import TabPanels from 'components/TabPanels';
import MembershipsTabContent from './MembershipsTabContent';
import OffersTabContent from './OffersTabContent';
import FeedTabContent from './FeedTabContent';

import { t } from 'i18n/index';

const OrganizationPage = () => {
  const params  = useParams();
  const organizationId = params.organizationId;
  const theme = useSuspense(ThemeResource.detail(), {id: organizationId})

  return (
    <PublicLayout theme={ theme } organizationPage={ true } >
      
      <TabPanels labels={[ t('organizationPage:feedTitle'), t('organizationPage:membershipsTitle'), t('organizationPage:benefitsTitle')]}>
                
                <FeedTabContent organizationId={organizationId} />
                <MembershipsTabContent organizationId={organizationId} />
                <OffersTabContent organizationId={organizationId} />

      </TabPanels>
    
    </PublicLayout>
  );
};

export default OrganizationPage;
