import React from 'react';
import PropTypes from 'prop-types';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const PaymentProviderHelper = ({helperText}) => {

  return (
    <div className='payment-provider-helper'>
          <ReactMarkdown>
                {helperText}
            </ReactMarkdown>
    </div>
  );
};

PaymentProviderHelper.propTypes = {
    helperText: PropTypes.string,
};

export default PaymentProviderHelper;