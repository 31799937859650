import React from 'react';
import PropTypes from 'prop-types';

const EmbeddedVideo = ({ embed }) => {

    const videoSrc =
        embed.source === 'youtube'
        ? `https://www.youtube.com/embed/${embed.id}`
        : `https://player.vimeo.com/video/${embed.id}`;

    return (
        <div className={`post-card-embeded-video-container ${embed.source == 'vimeo' && 'vimeo-embed-video'}`}>
                <iframe
                    width="560" 
                    height="315"
                    src={videoSrc}
                    allowFullScreen
                ></iframe>
        </div>
    );
};

EmbeddedVideo.propTypes = {
    embed: PropTypes.object,
};

export default EmbeddedVideo;