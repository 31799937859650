import { t } from 'i18n/index';

export const rewardType = {
  BEHAVIOUR: {
    value: "behaviour",
    label: t('rewardType:behaviour'),
  },
  ACHIEVEMENT: {
    value: "achievement",
    label: t('rewardType:achievement'),
  },
  COLLECTIBLE: {
    value: "collectible",
    label: t('rewardType:collectible'),
  },
  MYSTERYBOX: {
    value: "mysteryBox",
    label: t('rewardType:behaviour'),
  },
  PASS: {
    value: "pass",
    label: t('rewardType:behaviour'),
  }
};

export const activityType = {
  tuist:   'Surprise NFT',
};

export const claimState = {
  UNCLAIMED: {
    value : 'unclaimed',
    label: t('studentProfile:collectorsPortal_Unclaimed')
  },
  ONCHAIN: {
    value : 'onchain',
    label: t('claimState:collectorsPortal_Reward_Claimed_OnChain')
  },
  OFFCHAIN: {
    value : 'offchain',
    label: t('claimState:collectorsPortal_Reward_Claimed_OffChain')
  }
};

export const courseType = {
  MEMBERSHIP : {
    value: "membership",
    label: "Memberships"
  },
  EVENT : {
    value: "event",
    label: "Event Badges & Proof-of-Attendance"
  },
  SELLABLECOLLECTIBLES : {
    value: "sellableCollectibles",
    label: "Digital Collectibles & Art"
  },
  MYSTERYBOX : {
    value: "mysteryBox",
    label: "Surprise NFT"
  },
  COURSE : {
    value: "course",
    label: "Course Certificates & Awards"
  },
  CHALLENGE : {
    value: "challenge",
    label: "Treasure Hunt & Challenge"
  },
  WORKSHOP : {
    value: "workshop",
    label: "Other"
  },
};

export const paymentProviders = {
  STRIPE: {
    value: "stripe",
    label: "Stripe (Credit Card)",
    keys: [{
            value: "publishable_key",
            label: "Publishable Key",
            helper: `
**Publishable Key**
- Go to:[Stripe API Keys](https://dashboard.stripe.com/apikeys)
- Select "Standard keys" > "Publishable key"
- Copy
                    `
          }, {
            value: "api_key",
            label: "Restricted Key",
            helper: `
**Restricted Key**
- Go to [Stripe API Keys](https://dashboard.stripe.com/apikeys)
- Select: Create restricted keys
  - Key Name: Crowdclass Webhook
  - Select: "Write" for PaymentIntents
  - Select: Create Key
- Copy
                    `
          },
          {
            value: "webhook_secret",
            label: "Webhook Secret",
            helper: `
**Webhook Secret**
- Go to [Stripe Webhooks](https://dashboard.stripe.com/webhooks)
- Select: Add endpoint
- Copy URL:
  - https://api.crowdclass.io/webhooks/stripe
- Select: Events: payment_intent.succeed
- Click on: Reveal
- Copy
                    `
          }],
  },
//   EUPAGO: {
//     value: "eupago",
//     label: "Eupago",
//     keys: [{
//             value: "api_key",
//             label: "Chave API",
//             helper: `
// **Chave API**
// - Go to: [Eupago Backoffice](https://clientes.eupago.pt/backoffice/index.html)
// - Select: Canais > Adicionar canal
//   - Nome: Crowdclass Webhook
// - Select: Criar > Editar
// - Check: 'Receber notificação para um URL'
// - Copy URL:
//   - https://api.crowdclass.io/webhooks/eupago
// - Select: Guardar > Revelar Chave API > Copiar
//                     `,
//           }],
//   },
  XMONEY: {
    value: "utrust",
    label: "Xmoney (Crypto)",
    keys: [{
            value: "api_key",
            label: "API key",
            helper:  `
**API key**
- Go to: [Xmoney API Keys](https://merchants.crypto.xmoney.com/integrations/api_keys)
- Select: Type of integration: Custom (API)
- Copy: API key
                     `
          },{
            value: "webhook_secret",
            label: "Webhook secret",
            helper:  `
**Webhook secret**
- Go to: [Xmoney API Keys](https://merchants.crypto.xmoney.com/integrations/api_keys)
- Select: Type of integration: Custom (API)
- Copy: Webhook secret
                     `
          }],
  }
};
