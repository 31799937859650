import React from 'react'
import PropTypes from 'prop-types'

import TextField from 'components/formElements/TextField';

import { contentType } from 'utils/offersFormHelper';

const RedeemLimit = ({ offer,
                       onChange,
                       isDisabled,
                    }) => {
  return (
    <TextField name={ 'redeem_limit' }
        label={ offer.content_type === contentType.reveal ? "Redeem limit will be set to the main collection's supply limit." : "Total supply. Leave empty for unlimited supply" }
        value={ offer.redeem_limit ? offer.redeem_limit : '' }
        onChange={ onChange }
        disabled={ isDisabled }
        type={ "number" } />
  );
};

RedeemLimit.propTypes = {
    offer: PropTypes.object,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default RedeemLimit;