export default function processDevPayments(paymentIntentId) {
    const host = process.env.REACT_APP_API_HOST + "/webhooks/dev";
    let data = {payment_intent_id: paymentIntentId};
    fetch(host, {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }).then(res => {
      console.log("Request complete! response:", res);
    });
}
