import React from 'react';
import PropTypes from 'prop-types';

import EmbeddedVideo from './EmbeddedVideo';
import AttachedImage from './AttachedImage';
import AttachedVideo from './AttachedVideo';

import 'assets/css/postCard.css';

const PostCard = ({selectedPost}) => {
    const {body, embeds, image_urls, video_urls } = selectedPost;
    const formattedBody = body && body.replace(/\r\n/g, '<br />');

    const showBody = body
    const showEmbeds = embeds && embeds.length > 0;
    const showImages = image_urls && image_urls.length > 0;
    const showVideos = video_urls && video_urls.length > 0;

  return (
    <div className='post-card'>
        {showBody &&
          <div dangerouslySetInnerHTML={{ __html: formattedBody }} />
        }
        
        {showEmbeds &&
          embeds.map((embed, index) => (
            <EmbeddedVideo key={`embed-${index}`} embed={embed} />
          ))
        }
        
        <div className='post-card-attachments-container'>
          {showImages &&
            image_urls.map((imageUrl, index) => (
              <AttachedImage key={`image-${index}`} imageUrl={imageUrl} />
            ))
          }

          { showVideos && 
            video_urls.map((videoUrl, index) => (
              <AttachedVideo key={`video-${index}`} videoUrl={videoUrl} />
            ))
          }

        </div>
    </div>
  )
};

PostCard.propTypes = {
    selectedPost: PropTypes.object,
};  

export default PostCard;