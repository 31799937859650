import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { t } from 'i18n/index';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const airtable = "https://airtable.com"

function appendHiddentData(offer_instance_id, link, user, token) {
    if (link && !link.includes(airtable)) {
        return link;
    }

    const { first_name="", last_name="", email="", id="" } = user || {};
    const name = `${first_name} ${last_name}`.trim().replace(/\s/g, "%20");

    const fields = [
        `prefill_Nome=${name}`,
        `prefill_Email=${email}`,
        `prefill_user_id=${id}`,
        `prefill_offer_instance_id=${offer_instance_id}`,
        `prefill_token_id=${token.id}`,
        `prefill_token_serial=${token.serial_number}`,
        "hide_offer_instance_id=true",
        "hide_user_id=true",
        "hide_token_id=true",
        "hide_token_serial=true"]

    return `${link}?${fields.join("&")}`
}

const PostRedeemContent = ({ redeemedContent, token}) => {
    const { id, content_text, content_text_link, user } = redeemedContent || {};

    const link = appendHiddentData(id, content_text_link, user, token);

    return (
        <div>
            <DialogContent dividers >
                <ReactMarkdown>{content_text}</ReactMarkdown>
            </DialogContent>

            { content_text_link &&
                <DialogActions>
                    <Button variant="contained"
                            color="warning"
                            classes='MuiButton-outlinedWarning'
                            onClick={ () => window.open(link, '_blank') }>
                        {t('merch:redirectButton')}
                    </Button>
                </DialogActions>
            }
        </div>
    )
}

PostRedeemContent.propTypes = {
    redeemedContent: PropTypes.object,
    token: PropTypes.object,
};

export default PostRedeemContent;
