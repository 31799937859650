import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import LockIcon from '@mui/icons-material/Lock';
import OutlinedButton from 'components/buttons/OutlinedButton';

import 'assets/css/postCard.css';
import { t } from 'i18n/index';

const LockedPostCard = ({ selectedPost }) => {
    const { tier_titles } = selectedPost;
    
    const history = useHistory();

    const handleRedirect = () => {
        history.push(`?defaultIndex=1`)
    };

    return (
    <div className='post-card locked'>

        <div className={"locked-icon-container"} >
            <LockIcon sx={{ fontSize: 150 }} color="action" ></LockIcon>
        </div>

        {t('organizationPage:lockedPost')}
        {tier_titles.map((title, index) => (
            <li key={index}>
                {title}
            </li>
        ))}
        
        <div className={"locked-tiers-button-container"} >
            <OutlinedButton action={handleRedirect} description={t('organizationPage:seeMembershipTiersButton')} fullWidth={false}/>
        </div>
      
    </div>
  )
};

LockedPostCard.propTypes = {
    selectedPost: PropTypes.object,
    onClick: PropTypes.func,
};  

export default LockedPostCard;