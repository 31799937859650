import React from 'react'
import PropTypes from 'prop-types'

import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const AmountSelector = ({maxAmount, amount, handleAmount}) => {

    const atMaxAmount = maxAmount === amount;
    const atMinAmount = 1 === amount;

  return (
    <div className={`buy-actions-amount`}>
        <div className={`amount-selector`}>
            <IconButton className={`MuiAmountIconButton ${atMinAmount && "MuiAmountIconButtonDisabled"}`} onClick={() => handleAmount(amount - 1)} disableRipple={true} disabled={atMinAmount}>
                <RemoveIcon />
            </IconButton>
        </div>
        <div>
            {amount}
        </div>
        <div className={`amount-selector`}>
                <IconButton className={`MuiAmountIconButton ${atMaxAmount && "MuiAmountIconButtonDisabled"}`} onClick={() => handleAmount(amount + 1)} disableRipple={true} disabled={atMaxAmount}>
                    <AddIcon />
                </IconButton>
        </div>
    </div>
  )
}

AmountSelector.propTypes = {
    maxAmount: PropTypes.number,
    amount: PropTypes.number,
    handleAmount: PropTypes.func,
};

export default AmountSelector;