import React from 'react'
import PropTypes from 'prop-types'
import OfferCardDetails from './OfferCardDetails'
import OfferCardRewards from './OfferCardRewards'

const OfferCardContent = ({item, showEligibilityCriteria, preview, publicView}) => {
  return (
    <div className="fullwidth-card-content">
          <OfferCardDetails
            available_amount={item.available_amount}
            redeem_limit={item.redeem_limit}
            end_date={item.end_date}
            preview={preview}
          />

          {showEligibilityCriteria &&
            <OfferCardRewards
              item={ item }
              publicView={publicView}
            />
          }

    </div>
  )
};

OfferCardContent.propTypes = {
    item: PropTypes.object,
    showEligibilityCriteria: PropTypes.bool,
    preview: PropTypes.bool,
    publicView: PropTypes.bool,
};

export default OfferCardContent;