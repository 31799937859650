import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, Stack, Typography, FormGroup, Switch } from '@mui/material';

const ActiveState = ({active_state, onchange}) => {

  return <FormGroup>
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography>Inactive</Typography>
      <FormControlLabel control={<Switch
        name='active_state'
        color="warning"
        checked={active_state}
        onChange={ onchange } />} />
      <Typography>Active</Typography>
    </Stack>
  </FormGroup>;
}

ActiveState.propTypes = {
  active_state: PropTypes.bool.isRequired,
  onchange: PropTypes.func.isRequired
};

export default ActiveState;


