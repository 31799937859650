import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/layouts/index'
import PostsList from './PostsList';

const pageInfo = {
  name: 'Exclusive Content',
}

const Posts = () => {

  return (
    <Layout context='teacher'
            activeMenuItem='content'
            pageInfo={pageInfo}>
      <PostsList />
    </Layout>
  )
};

Posts.propTypes = {
    organizationId: PropTypes.number,
};

export default Posts;
