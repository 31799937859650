import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class PostResource extends AuthenticatedResource {
  static urlRoot = '/posts' as const;

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}
