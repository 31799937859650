import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import OrganizationOfferResource from 'resources/organization/OfferResource';

import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import StatusModal from 'components/StatusModal';
import Layout from 'components/layouts';
import Form from './form'
import WithPermission from 'components/WithPermission';
import OffersFallback from 'components/fallbacks/Offers';
import { contentType } from 'utils/offersFormHelper';

const pageInfo = {
  name: "Create new offer"
};
const NewOffer = () => {

    const { fetch } = useController();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const params = useParams();
    const organizationId = parseInt(params.organizationId);

    /*HANDLE FUNCTIONS*/
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleValidation = (offer, file, reveal_file) => {
      const {title, description, blockchain, contract_address, course_id, content_type, content_text, content_collections} = offer;
      validateTitle(title);
      validateDescription(description);
      validateImage(file);
      validateAttributes(blockchain, contract_address, course_id);
      validateContent(content_type, content_collections, content_text, reveal_file);
    };

    /*VALIDATION FUNCTIONS*/
    const validateTitle = (title) => {
      if (title.trim() === ""){
        throwError("Please provide a title.");
      }
    };

    const validateDescription = (description) => {
      if (description.trim() === ""){
        throwError("Please provide a description.");
      }
    };

    const validateImage = (file) => {
      if (!file) {
        throwError("Please upload an image.");
      }
    };

    const validateAttributes = (blockchain, contract_address, course_id) => {
      if (!course_id && (!blockchain || !contract_address)) {
        throwError("Please complete the entitlement settings.");
      }
    };

    const validateContent = (content_type, content_collections, content_text, reveal_file) => {
      if (!Object.values(contentType).includes(content_type)) {
        throwError("Please select a content type.");
      } else if (content_type === contentType.reveal && content_collections.length <= 0) {
        throwError("If you would like to set this offer's content as Reveal, please select at least one associated collection.");
      } else if (content_type === contentType.reveal && !reveal_file) {
        throwError("If you would like to set this offer's content as Reveal, please upload an image to replace that of the reward used to redeem the offer.");
      } else if (content_type === contentType.text && (!content_text || content_text.trim() === "")) {
        throwError("If you would like to set this offer's content as Unlockable Content, please tell us what message you would like us to display on redeem.");
      }
    };

    const throwError = (errorMessage) => {
      setError(errorMessage);
      handleOpenErrorModal();
      throw '';
    };

    const { setOpen, setMessage, setSeverity } = useToast();

    const createFormData = (offer, file, reveal_file) => {
      const formData = new FormData();
      const fields = [

        { key: 'title', value: offer.title },
        { key: 'description', value: offer.description },
        { key: 'redeem_limit', value: offer.redeem_limit },
        { key: 'end_date', value: offer.end_date },
        { key: 'offer_type', value: offer.offer_type },
        { key: 'content_type', value: offer.content_type },

        { key: 'reward_id', value: offer.reward_id },
        { key: 'course_id', value: offer.course_id },

        { key: 'blockchain', value: offer.blockchain },
        { key: 'contract_address', value: offer.contract_address },
        { key: 'eligible_attributes', value: offer.eligible_attributes },

        { key: 'text', value: offer.content_text },
        { key: 'link', value: offer.content_text_link },

        { key: 'collections', value: offer.content_collections },

        { key: 'image', value: file }
      ]

      if (reveal_file) {
        fields.push({ key: 'reveal_image', value: reveal_file });
      }

      fields.forEach(field => {
        formData.append(field.key, field.value);
      });
      return formData;
    };

    const handleOnSubmit = useCallback(
      async (e, offer, file, reveal_file) => {
        e.preventDefault();

        handleValidation(offer, file, reveal_file);
        try {
          const formData = createFormData(offer, file, reveal_file);
          const { id } = await fetch(OrganizationOfferResource.create(), {organization_id: organizationId}, formData);

          // success!
          if (id){
            fetch(OrganizationOfferResource.list(), {organization_id: organizationId});
            customToast('success', 'Offer created successfully', setOpen, setSeverity, setMessage);
            history.goBack();
          }
        } catch (error) {
          const data = await error.response.json();
          setError(data.errors.base[0]);
          handleOpenErrorModal();
        }
      },
      [fetch],
    );

    return (
        <Layout context='teacher'
                back={ true }
                pageInfo={ pageInfo }
                activeMenuItem='benefits'>

            <WithPermission permission={'hasOffers'} fallback={<OffersFallback />}>
              <Form onSubmit={ handleOnSubmit } />
              { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
            </WithPermission>

        </Layout>
    );
};

export default NewOffer;
