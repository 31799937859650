import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'

const AttachmentChip = ({filenames, type, onDelete}) => {
    return (
        filenames.map((filename, index) => (
                <Chip key={`${type}-chip-${index}`} 
                        label={filename}
                        variant="outlined"
                        onDelete={onDelete(type, index)}
                        className="attachment-chip"/>
        ))
    );
};

AttachmentChip.propTypes = {
    filenames: PropTypes.array,
    type: PropTypes.string,
    onDelete: PropTypes.func,
};

export default AttachmentChip;