import React from 'react';
import PropTypes from 'prop-types';

import LoadingButton from "components/buttons/LoadingButton";
import StripePaymentSubForm from './StripePaymentSubForm';
import "./stripe.css"

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { t } from 'i18n/index';

const StripePaymentModal = ({ gift,
                              finalPrice,
                              currencySymbol,
                              clientSecret,
                              handleStep,
                              handleMessage,
                              handleErrorFindingReward,
                              publishableKey
                           }) => {

  const stripePromise = loadStripe(publishableKey, {locale: 'auto'});

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#e55e29',
    },
  };
  
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret ? 
        <Elements options={options} stripe={stripePromise}>
          <StripePaymentSubForm gift={ gift }
                                finalPrice={ finalPrice }
                                currencySymbol={ currencySymbol }
                                handleStep={ handleStep }
                                handleMessage={ handleMessage }
                                handleErrorFindingReward={ handleErrorFindingReward }
                                />
        </Elements>
      :
        <>
          <p>{t('checkoutForm:preparingYourOrder')}</p>
          <LoadingButton />
        </>
      }
    </div>
  );
}

StripePaymentModal.propTypes = {
  gift: PropTypes.bool,
  finalPrice: PropTypes.number,
  currencySymbol: PropTypes.string,
  clientSecret: PropTypes.string,
  handleStep: PropTypes.func,
  handleMessage: PropTypes.func,
  handleErrorFindingReward: PropTypes.func,
  publishableKey: PropTypes.string,
};


export default StripePaymentModal;