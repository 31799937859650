import AuthenticatedResource from 'resources/public/AuthenticatedResource';

export default class PaymentProviderResource extends AuthenticatedResource {
    static urlRoot = '/organizations/:organization_id/payment_provider' as const;

    readonly id: number | undefined = undefined;

    pk() {
        return this.id?.toString();
    }
}