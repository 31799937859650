import gift from "../assets/images/gift-83-256.png";

export const colorItems = [
    {
      value: "metadata_box_color",
      label: "Metadata Color",
    },
    {
      value: "metadata_text_color",
      label: "Metadata Text Color",
    },
    {
      value: "primary_background_color",
      label: "Primary Background Color",
    },
    {
      value: "title_color",
      label: "Title Color",
    },
    {
      value: "text_color",
      label: "Text Color",
    },
    {
      value: "secondary_background_color",
      label: "Secondary Background Color",
    },
    {
      value: "buy_button_color",
      label: "Button Color",
    },
    {
      value: "button_text_color",
      label: "Button Text Color",
    },
    {
      value: "offer_card_background_color",
      label: "Offer Card Color",
    },
    {
      value: "offer_card_text_color",
      label: "Offer Card Text Color",
    },
  ];

  export const fontSizeItems = [
    {
      value: "title_size",
      label: "Title Font Size",
    },
    {
      value: "subtitle_size",
      label: "Subtitle Font Size",
    },
    {
      value: "description_size",
      label: "Description Font Size",
    },
  ];

  export const fontFamilyOptions = [
    {
      value: 'Helvetica',
      label: 'Helvetica',
    },
    {
      value: 'Arial',
      label: 'Arial',
    },
    {
      value: 'Tahoma',
      label: 'Tahoma',
    },
    {
      value: 'Verdana',
      label: 'Verdana',
    },
    {
      value: 'Times New Roman',
      label: 'Times New Roman',
    },
    {
      value: 'Syne',
      label: 'Syne',
    },
    {
      value: 'Raleway',
      label: 'Raleway',
    },
    {
      value: 'Playfair Display',
      label: 'Playfair Display',
    }
  ];

  export const defaultReward = {
    "id": 1,
    "course_id": 1,
    "title": "CrowdClass NFT",
    "description": "NFT Description",
    "is_public": true,
    "category": "collectible",
    "media_type": "image",
    "image_url": gift,
    "supply_limit": null,
    "active_state": true,
    "end_date": null,
    "sellable": true,
    "pricings": [
      {
        "id": 1,
        "reward_id": 1,
        "pricing_type": "fixed",
        "frequency": "lifetime",
        "price": 1000,
        "currency": "EUR",
        "active": true
      }
    ],
    "claim_limit": 10,
    "metadata": [],
    "stats": {
      "issued": 0,
      "claimed": 0
    },
    "course": "Crowdclass Collection",
    "course_link": null,
    "course_category": "sellableCollectibles",
    "organization": {
      "id": 1,
      "title": "Crowdclass Organization"
    },
    "participant": {
      "logged_in": false,
      "name": "",
      "has_claimed": false,
      "has_been_issued": false,
      "issued_count": 0,
      "is_enrolled": false,
      "can_claim": true
    }
  };
