import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams} from "react-router-dom";

import logo from 'assets/images/logo-crowdclass.svg';

import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PeopleIcon from '@mui/icons-material/People';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import { getLastVisitedCourse } from 'utils/getLastVisitedCourse';
import { IconButton, Tooltip } from '@mui/material';

const menuActions = {
  'content': { link: 'content/new', Icon: AddCircleOutlineIcon, tooltip: 'Create new post' },
}

const Teacher = ({ organizationsList, activeMenuItem=0 }) => {
  const params = useParams();

  const organizationId = params.organizationId;
  const currentOrganization = organizationsList && organizationsList.find(org => org.id === parseInt(organizationId));
  const { title, membership_id } = currentOrganization || {};

  const lastCourse = getLastVisitedCourse();

  const menuItems = [
    { id: 'memberships', text: 'Memberships', Icon: ConfirmationNumberIcon, link: `memberships/${membership_id}` },
    { id: 'content', text: 'Content', Icon: ArticleIcon, link: 'content' },
    { id: 'collectibles', text: 'Collectibles', Icon: LocalPoliceIcon, link: `collections/${lastCourse}` },
    { id: 'benefits', text: 'Benefits', Icon: RedeemTwoToneIcon, link: 'offers' },
    { id: 'community', text: 'Community', Icon: PeopleIcon, link: 'participants' },
    { id: 'analytics', text: 'Reports & Analytics', Icon: WidgetsIcon, link: 'analytics' },
    { id: 'settings', text: 'Admin Settings', Icon: SettingsIcon, link: 'settings' },
  ];

  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>

      <h2 className='center orange'>{title}</h2>
      {menuItems.map(({id, text, Icon, link}) => (
        <ListItemAction key={id} selected={ id == activeMenuItem } menuId={id} organizationId={organizationId}>
          <ListItemButton
                          selected={ id == activeMenuItem }
                          component={Link}
                          disabled={!organizationId}
                          to={ `/organizations/${organizationId}/${link}`}
                          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={ text } />
          </ListItemButton>
        </ListItemAction>
      ))}

    </div>
  );
};

// eslint-disable-next-line react/prop-types
const ListItemAction = ({ selected, menuId, organizationId, children }) => {
  const action = menuActions[menuId];

  if (!action) { return children;}

  const { link, Icon, tooltip } = action;
  const fullLink = `/organizations/${organizationId}/${link}`;

  return (
    <ListItem component="div" disablePadding>
      { children }

      <Tooltip title={tooltip}>
      <IconButton className={ selected ? 'selected' : ''} size="large">
        <Link to={ fullLink }>
          <Icon />
        </Link>
      </IconButton>
      </Tooltip>
    </ListItem>
  )
}

Teacher.propTypes = {
  organizationsList: PropTypes.array,
  activeMenuItem: PropTypes.string
};

export default Teacher;
