import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BackdropModal from 'components/BackdropModal';
import VideoPreview from 'components/VideoPreview';

export const Media = ({id, title, media_type, image_url, video_url }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <div className="badge-image">
      {media_type == "video" &&
        <VideoPreview url={video_url} poster={image_url} playAudio={false} />}
      {media_type == "image" &&
        <img src={image_url} className="badge-image" alt={`${title} Badge`} onClick={handleToggle} />}
    </div>
    <div className="backdrop-image">
      <BackdropModal
        open={open}
        handleClose={handleClose}
        imageUrl={image_url}
        title={title}
        handleToggle={handleToggle}
        showDownloadButtons={true}
        id={id} />
    </div>
  </>;
};

Media.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  media_type: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  video_url: PropTypes.string,
};

export default Media;
