import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import AuthModal from 'components/authForms/AuthModal';
import PostsGrid from 'components/CardGrids/PostsGrid';
import FloatingButton from 'components/buttons/FloatingButton';
import StandardButton from 'components/buttons/StandardButton';

import PostResource from 'resources/public/PostResource';

import isMobileDevice from 'utils/mobileAndTabletCheck';
import { useLogout } from 'utils/useLogout';
import UserName from 'utils/localStorage/UserName';

import { Link } from '@mui/material';
import { t } from 'i18n/index';

const FeedTabContent = ({ organizationId }) => {
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const {fetch} = useController();

    const posts = useSuspense(PostResource.list(), {organization_id: organizationId})
    const updatePostList = async () => {
        await fetch(PostResource.list(), {organization_id: organizationId})
    };

    const logout = useLogout();
    const redirect = false;
    const userName = UserName.read();

    const isMobile = isMobileDevice();

    return (
        <div>
            <div className="header">
                { userName &&
                    <p>
                        {t('publicClaimPage:currentlyLoggedIn')}&nbsp;
                        <b>{ userName }</b>.&nbsp;
                        <Link underline="hover" color="inherit" onClick={ () => logout(redirect) }>{t('publicClaimPage:logOut')}</Link>
                    </p>
                }
                { (!userName && isMobile) &&
                    <StandardButton action={() => setOpenAuthModal(true)} description={t('signUpPage:signUpModal_SignIn')} fullWidth={true} />
                }               
                { (!userName && !isMobile) &&
                    <FloatingButton action={() => setOpenAuthModal(true)} description={t('signUpPage:signUpModal_SignIn')} />
                }
            </div>


            <PostsGrid data={posts}/>

            <AuthModal open={ openAuthModal }
                    onClose={ () => setOpenAuthModal(false) }
                    done={ updatePostList } />

        </div>
    );
};

FeedTabContent.propTypes = {
    organizationId: PropTypes.number
};

export default FeedTabContent;