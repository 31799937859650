import React from 'react'
import PropTypes from 'prop-types'
import { Fab } from '@mui/material';

const FloatingButton = ({action, description}) => {
  return (
    <Fab variant="extended" className='floating-button' onClick={action}>
      {description}
    </Fab>
  )
};

FloatingButton.propTypes = {
  action: PropTypes.func,
  description: PropTypes.string,
};

export default FloatingButton;