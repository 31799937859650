import { React } from 'react';
import PropTypes from 'prop-types';

import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function newTierUrl(organizationId, membershipId) {
  return `/organizations/${organizationId}/memberships/${membershipId}/tiers/new`;
}

function paymentProvidersUrl(organizationId) {
  return `/organizations/${organizationId}/settings/new?defaultIndex=3`;
}

import './onboarding.css';

const Onboarding = ({ organizationId, membershipId, hasTiers, hasPaymentProviders }) => {

  return (
    <div className="onboarding-checklist">
    <div className="oc">
      <div className="oc_step">
        { !hasTiers ? <LooksOneRoundedIcon fontSize="large" /> : <CheckBoxIcon  fontSize="large" color="success" /> }
      </div>
      <div className="oc_title">Add a membership tier</div>
      <div className="oc_description">Click on the <a href={ newTierUrl(organizationId, membershipId) }>New Tier</a> button to create your first membership tier.</div>
    </div>

    <div className="oc">
      <div className="oc_step">
        { !hasPaymentProviders ? <LooksTwoRoundedIcon fontSize="large" /> : <CheckBoxIcon  fontSize="large" color="success" /> }
      </div>
      <div className="oc_title">Add Payment Provider</div>
      <div className="oc_description">Configure your <a href={ paymentProvidersUrl(organizationId) }>Stripe account integration</a> to start collecting payments.</div>
    </div>

    <div className="oc">
      <div className="oc_step">
        <Looks3RoundedIcon fontSize="large"/>
      </div>
      <div className="oc_title">Publish page</div>
      <div className="oc_description">Once you finish steps 1 and 2, we will review your Memberships page. You&apos;ll receive an email as soon as it&apos;s published.</div>
    </div>

  </div>
  );
};

Onboarding.propTypes = {
  organizationId: PropTypes.number.isRequired,
  membershipId: PropTypes.number.isRequired,
  hasTiers: PropTypes.bool.isRequired,
  hasPaymentProviders: PropTypes.bool.isRequired
};

export default Onboarding;
