import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControlLabel } from '@mui/material';
import { t } from 'i18n/index';

const GiftCheckbox = ({gift, handleGift}) => {
  return (
    <FormControlLabel
        control={<Checkbox checked={gift}
            className='gift-checkbox'
            onChange={handleGift}
            label={t('checkoutForm:gift')}
            inputProps={{ 'aria-label': 'primary checkbox' }} />}
        label={t('checkoutForm:gift')} />
  )
}

GiftCheckbox.propTypes = {
    gift: PropTypes.bool,
    handleGift: PropTypes.func,
}

export default GiftCheckbox