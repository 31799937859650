import React from 'react';
import PropTypes from 'prop-types';

import OfferCard from 'components/Cards/OfferCard'

const OffersGrid = ({ data, onRedeem, publicView }) => {    

    return (   
        data.map((item) => {

            return (
                <div key={item.id}>

                    <OfferCard item={ item }
                               publicView={ publicView }
                               onRedeem={ onRedeem }
                               />

                </div>
            );
        })
    );
};

OffersGrid.propTypes = {
    data:PropTypes.object,
    onRedeem: PropTypes.func,
    publicView: PropTypes.bool,
};

export default OffersGrid;
