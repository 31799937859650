import React from 'react';
import PropTypes from 'prop-types';

import AmountSelector from './AmountSelector';
import GiftCheckbox from './GiftCheckbox';
import BuyButton from './BuyButton';
import FrequencySwitcher from './FrequencySwitcher';
import Price from './Price';

import { convertPriceFromCents, currencySymbol } from 'utils/rewards';

import { t } from 'i18n/index';

const BuyActions = ({ maxAmount, onBuy, price, currency, supply_limit, amount, handleAmount, gift, handleGift, tier, frequency, onFrequencyChange, frequencies }) => {

    const convertedPrice = convertPriceFromCents(price);
    const orderPrice = (convertedPrice * amount).toFixed(2);

    return (
        <div className={"grid buy-actions-container"} >
            <div className='price'>
                 <Price price={price} currency={currency} />
            </div>
            <div className='amount'>
                {!tier &&<AmountSelector maxAmount={maxAmount} amount={amount} handleAmount={handleAmount} />}
            </div>
            <div className='button'>
                <BuyButton onClick={onBuy} oneOffPayment={frequencies.length === 1} tier={tier}/>
            </div>
            <div className='supply'>
                {tier ? <FrequencySwitcher frequency={frequency} onFrequencyChange={onFrequencyChange} frequencies={frequencies} /> : supply_limit !== null && `${t('checkoutForm:inStock')} ${supply_limit}`}
            </div>
            <div className='total'>
                {!tier && <>Total: {orderPrice} {currencySymbol[currency]}</>}
            </div>
            <div className='gift-checkbox'>
                {!tier && <GiftCheckbox gift={gift} handleGift={handleGift} />}
            </div>
        </div>
    );
};

BuyActions.propTypes = {
    amount: PropTypes.number,
    handleAmount: PropTypes.func,
    maxAmount: PropTypes.number,
    onBuy: PropTypes.func,
    price: PropTypes.number,
    currency: PropTypes.string,
    stats: PropTypes.object,
    supply_limit: PropTypes.string,
    gift: PropTypes.bool,
    handleGift: PropTypes.func,
    tier: PropTypes.bool,
    frequency: PropTypes.string,
    onFrequencyChange: PropTypes.func,
    frequencies: PropTypes.bool,
}

export default BuyActions;
