import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import OrganizationResource from 'resources/organization/OrganizationResource';

import AddMemberModal from './AddMemberModal';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { membersColumns } from 'utils/columns/membersColumns';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';

import { Button } from '@mui/material';

const MembersForm = ({ managers, organizationId, handleError }) => {

    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const { setOpen, setMessage, setSeverity } = useToast();
    const { fetch } = useController();

    const handleInviteMember = useCallback(
        async (email) => {
          try {
            const { id } = await fetch(OrganizationResource.update(), { id: organizationId }, { email });
    
            // success!
            if (id){
              fetch(OrganizationResource.list());
              customToast('success', `New organization member invited`, setOpen, setSeverity, setMessage);
              setShowAddMemberModal(false);
            }
          }  catch (error) {
            handleError(error.message); 
          }
        },
        [fetch, organizationId],
    );
      
  return (
    <div>
          <p>Team members have accounts on Crowdclass and can create new collections and NFTs or change their settings.</p>            

          <DataSelectionTable records={managers}
                            columns={membersColumns}
                            checkboxSelectRows={false}
          />

          <Button variant="contained" color="primary" onClick={ () => setShowAddMemberModal(true) }>Add Member</Button>
          <AddMemberModal open={ showAddMemberModal }
                          onSubmit={ handleInviteMember }
                          onClose={ () => setShowAddMemberModal(false) } />
        </div>
  );
};

MembersForm.propTypes = {
    managers: PropTypes.object,
    organizationId: PropTypes.number,
    handleError: PropTypes.func,
};

export default MembersForm;