import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";

import RedeemResource from 'resources/public/RedeemResource';
import ContentResource from "resources/user/ContentResource";

import RedeemModal from "containers/student/Profile/Offers/RedeemModal";
import AuthModal from 'components/authForms/AuthModal';
import StatusModal from "components/StatusModal";

import FullwidthCard from "../FullwidthCard";
import Image from "./OfferCardImage";
import Header from "./OfferCardHeader";
import Content from "./OfferCardContent";
import Footer from "./OfferCardFooter";

import AuthToken from 'utils/localStorage/AuthToken';
import { offerType } from "utils/offersFormHelper";

import { t } from 'i18n/index';

const OfferCard = ({ item, image, preview, publicView, onRedeem }) => {
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [offer, setOffer] = useState(null);
  const [error, setError] = useState(null);
  const { fetch } = useController();

  const showRedeemButton = !item.redeem_state;
  const showEligibilityCriteria = !preview
  
  const authToken = AuthToken.read();
  const handlePublicRedeem = (item) => {
    if (authToken) {
      handleRedeemAttempt(item)
    } else { 
      setOpenAuthModal(true); 
    }
  };

  const handleRedeemAttempt = async (offer) => {
    try {
      const response = await fetch(RedeemResource.list(), {organization_id: offer.organization_id});
      const foundOffer = response.find(element => element.id === offer.id);
      const eligible = foundOffer["eligible"]
      setOpenAuthModal(false); 
      if (eligible) {
        setOpenRedeemModal(true);
      } else {
        setError(t('offersLabels:notEligible'));
        setOpenErrorModal(true);
      }
    } catch (error) {
      setError(error.message);
      setOpenErrorModal(true);
    }
  };

  const textOfferInstance = !!item.redeem_state && item.text_offer

  const buildFormData = ( id, wallet_address, signature, challenge) =>{
    const data = new FormData();
    data.append('wallet_address', wallet_address);
    data.append('signature', signature);
    data.append('challenge', challenge);
    return data
  };

  const handleExternalOfferInstanceContent = async ({ wallet_address, challenge, signature }) => {
    if (textOfferInstance && !item.internal) {
      try {
        const data = buildFormData(item.id, wallet_address, signature, challenge);
        const offer = await fetch(ContentResource.create(), {id: item.id}, data);
        setOffer(offer);
      } catch (error) {
        const { errors } = await error.response.json();
        const errorMessage = errors ? errors.base[0] : error.message;
        setError(errorMessage);
        setOpenErrorModal(true);
      }
    }
  };

  return (
    <div>
      <FullwidthCard preview={preview}
                     image={<Image image={ image } 
                                  title={ item.title }
                                  image_url={ item.image_url } />}
                     header={<Header item={item} 
                                  textOfferInstance={textOfferInstance}
                                  offer={offer} 
                                  handleExternalOfferInstanceContent={handleExternalOfferInstanceContent}
                                  preview={preview}
                                  />}
                     content={<Content item={item}
                                  showEligibilityCriteria={showEligibilityCriteria} 
                                  preview={preview} 
                                  publicView={publicView} />} 
                     footer={<Footer state={item.redeem_state}
                                  showRedeemButton={showRedeemButton}
                                  onRedeem={publicView ? () => handlePublicRedeem(item) : () => setOpenRedeemModal(true)}
                                  preview={preview} />} />
                                  
      <RedeemModal open={ openRedeemModal }
                   title={ item.title }
                   reward={ item.eligible_rewards && item.eligible_rewards.length ? item.eligible_rewards[0] : null }
                   course={ item.reward_course && item.reward_course.title }
                   onClose={  () => setOpenRedeemModal(false)}
                   offerId={ item.id }
                   internal={ item.offer_type === offerType.internal }
                   onRedeem={ onRedeem } />

      <AuthModal open={ openAuthModal }
                 onClose={ () => setOpenAuthModal(false) }
                 done={ () => handleRedeemAttempt(item) }
                 offer={ item } />

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ () => setOpenErrorModal(false) }/>}

    </div>
  );
};

OfferCard.propTypes = {
  item: PropTypes.object,
  image: PropTypes.object,
  preview: PropTypes.bool,
  publicView: PropTypes.bool,
  onRedeem: PropTypes.func,
};

export default OfferCard;