import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useController, useSuspense } from '@rest-hooks/react';

import PaymentProvidersResource from 'resources/organization/PaymentProviderResource';

import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import { paymentProviders } from 'utils/constants';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';

const PaymentProviderAdminList = ({organizationId, onClick, handleError}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const { setOpen, setMessage, setSeverity } = useToast();

    const data = useSuspense(PaymentProvidersResource.detail(), {organization_id: organizationId});
    const organizationPaymentProviders = data.providers.map((provider) => provider.provider);

    const isSupportedProvider = (provider) => {
        return organizationPaymentProviders.includes(provider);
    };

    const { fetch } = useController();
    const handleDelete = async (value) => {
        try {
            const correspondingId = data.providers.find((element) => element.provider === value).id;
            await fetch(PaymentProvidersResource.delete(), {organization_id: organizationId, id: correspondingId});
            await fetch(PaymentProvidersResource.detail(), {organization_id: organizationId});
            customToast('success', `${selectedProvider.label} Deleted`, setOpen, setSeverity, setMessage);
            setSelectedProvider(null);
        } catch (error) {
            handleError(error)
        }
    };

    const handleConfirmDeleteModal = (label, value) => {
        setSelectedProvider({label: label, value: value})
        setConfirmDelete(true)
    };

  return (
    <div>
        <List>
            {Object.entries(paymentProviders).map(([key, value]) => (
                <ListItem 
                    key={key}
                    sx={{backgroundColor: "#ffff", marginTop: '2px'}}>
                    <ListItemText primary={value.label} />
                    <ListItemIcon>
                    {isSupportedProvider(value.value) ? (
                        <CheckIcon color="success" />
                    ) : (
                        <ClearIcon color="error" />
                    )}
                    </ListItemIcon>
                    <IconButton onClick={() => onClick(value)}>
                        <EditIcon color="warning" />
                    </IconButton>
                    <IconButton onClick={() => handleConfirmDeleteModal(value.label, value.value)}>
                        <DeleteIcon color="error" />
                    </IconButton>
                </ListItem>
            ))}
        </List>
        <ConfirmDialog
                title={`Deleting ${selectedProvider && selectedProvider.label} setup`}
                open={ confirmDelete }
                setOpen={setConfirmDelete}
                onConfirm={ () => handleDelete(selectedProvider.value) }
            >
                After deleting {selectedProvider && selectedProvider.label} setup you will not be able to accept payments through it?
        </ConfirmDialog>
    </div>
  );
};

PaymentProviderAdminList.propTypes = {
    organizationId: PropTypes.number,
    onClick: PropTypes.func,
    handleError: PropTypes.func,
};

export default PaymentProviderAdminList;