import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Memberships from 'containers/teacher/Memberships';
import Dashboard from 'containers/teacher/Dashboard';
import OrganizationSettings from 'containers/teacher/Settings';

import ImportStudent from 'containers/teacher/Student/Wizard/import';
import NewStudent from 'containers/teacher/Student/Wizard/new';
import Students from 'containers/teacher/Student';

import Analytics from 'containers/teacher/Analytics';

import NewReward from 'containers/teacher/Reward/Wizard/new';
import UpdateReward from 'containers/teacher/Reward/Wizard/update';
import SendReward from 'containers/teacher/Reward/Send';

import NewCourse from 'containers/teacher/Course/Wizard/new';
import UpdateCourse from 'containers/teacher/Course/Wizard/update';
import OffersTeacher from 'containers/teacher/Offers';
import UpdateOffer from 'containers/teacher/Offers/Wizard/update';
import NewOffer from 'containers/teacher/Offers/Wizard/new';

import StudentProfile from 'containers/student/Profile';
import NewOrganization from 'containers/teacher/Course/Wizard/newOrg';

import Post from 'containers/teacher/Post';
import NewPost from 'containers/teacher/Post/Wizzard/new';
import UpdatePost from 'containers/teacher/Post/Wizzard/update';

import AdminUsers from 'containers/admin/Users';
import AdminCourses from 'containers/admin/Courses';
import AdminCoursesView from 'containers/admin/Courses/View';
import AdminOrganizations from 'containers/admin/Organizations';

import ClaimReward from 'containers/public/ClaimReward';
import PublicReward from 'containers/public/publicReward';
import Invite from 'containers/account/invite';
import ForgotPassword from 'containers/account/forgotPassword';
import ResetPassword from 'containers/account/resetPassword';
import Login from 'containers/account/login';
import Offers from 'containers/student/Profile/Offers';
import UserOrders from 'containers/student/Profile/Orders';
import UserSettings from 'containers/student/Settings';
import OrganizationPage from 'containers/public/Organization';

const Routes = () => {
  return(
    <BrowserRouter>
      <Switch>

        { /* Admin routes*/ }
        <Route path="/admin/users" component={ AdminUsers } />
        <Route path="/admin/collections/:courseId" component={ AdminCoursesView } />
        <Route path="/admin/collections" component={ AdminCourses } />
        <Route path="/admin/organizations" component={ AdminOrganizations } />

        { /* Organization routes*/ }
        <Route path="/organizations/:organizationId/memberships/:courseId/tiers/new" >
          <NewReward membership={ true } />
        </Route>
        <Route path="/organizations/:organizationId/memberships/:courseId" component={ Memberships } />

        <Route path="/organizations/:organizationId/collections/new" component={ NewCourse } />

        <Route path="/organizations/:organizationId/collections/:courseId/collectible/:rewardId/edit" component={ UpdateReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/collectible/:rewardId" component={ SendReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/collectibles/new" component={ NewReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/collectibles" component={ Dashboard } />

        <Route path="/organizations/:organizationId/collections/:courseId/participants/import" component={ ImportStudent } />
        <Route path="/organizations/:organizationId/collections/:courseId/participants/new" component={ NewStudent } />

        <Route path="/organizations/:organizationId/collections/:courseId/edit" component={ UpdateCourse } />
        <Route path="/organizations/:organizationId/collections/:courseId/" component={ Dashboard } />

        <Route path="/organizations/:organizationId/participants" component={ Students } />
        <Route path="/organizations/:organizationId/analytics" component={ Analytics } />

        <Route path="/organizations/:organizationId/offers/:offerId/edit" component={ UpdateOffer } />
        <Route path="/organizations/:organizationId/offers/new" component={ NewOffer } />
        <Route path="/organizations/:organizationId/offers/" component={ OffersTeacher } />

        <Route path="/organizations/:organizationId/content/:postId/edit" component={ UpdatePost } />
        <Route path="/organizations/:organizationId/content/new" component={ NewPost } />
        <Route path="/organizations/:organizationId/content/" component={ Post } />

        <Route path="/organizations/:organizationId/settings" component={ OrganizationSettings } />

        { /* Participant routes*/ }
        <Route path="/profile" component={ StudentProfile } />
        <Route path="/settings" component={ UserSettings } />
        <Route path="/offers/:organizationId" component={ Offers } />
        <Route path="/offers" component={ Offers } />
        <Route path="/orders" component={ UserOrders } />
        <Route path="/organizations/new" component={ NewOrganization } />

        { /* Public routes*/ }
        <Route path="/organization/:organizationId" component={ OrganizationPage } />
        <Route path="/claim/:organizationId/preview" component={ ClaimReward } />
        <Route path="/claim/:tokenId/:resetToken" component={ ClaimReward } />
        <Route path="/claim/:tokenId/" component={ ClaimReward } />
        <Route path="/tokens/:tokenId/" component={ PublicReward } />
        <Route path="/invites/:inviteId/" component={ Invite } />
        <Route path="/password_resets/:resetToken" component={ ResetPassword } />
        <Route path="/password_resets" component={ ForgotPassword } />
        <Route path="/" component={ Login } />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;
