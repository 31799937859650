import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import FullwidthCard from '../FullwidthCard';
import Image from './TierCardImage';
import Header from './TierCardHeader';
import Content from './TierCardContent';

const TierCard = ({ title, description, claim_token, media_type, image_url, video_url, pricings, already_issued_to}) => {
  const [frequency, setFrequency] = useState(pricings.length > 0 ? pricings[0].frequency : null);
  const free = pricings.length === 0;
  const oneOffPayment = pricings.length === 1;
  
  const history = useHistory();

  const handleRedirect = () => {
    const url = `/claim/${claim_token}${frequency ? `?frequency=${frequency}` : ''}`;
    history.push(url);
  };

  return (
    <FullwidthCard image={<Image media_type={media_type}
                                video_url={video_url}
                                title={title}
                                image_url={image_url} />}
                   header={<Header title={title}
                                free={free}
                                pricings={pricings}
                                frequency={frequency}
                                onFrequencyChange={setFrequency}
                                onClick={handleRedirect}
                                already_issued_to={already_issued_to}
                                oneOffPayment={oneOffPayment} />}
                   content={<Content description={description} />} />
)};

TierCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  claim_token: PropTypes.string,
  media_type: PropTypes.string,
  image_url: PropTypes.string,
  video_url: PropTypes.string,
  pricings: PropTypes.array,
  already_issued_to: PropTypes.bool,
};

export default TierCard;
