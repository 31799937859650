import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material';

import { t } from 'i18n/index';

const OfferCardFooter = ({ state, showRedeemButton, onRedeem, preview}) => {
  return (
    <div className={`fullwidth-card-footer`}>
        {state &&
            <div>
                <h4 className='green'>{t('offersLabels:offerCardLabelRedeemedState')}</h4>
                { state === 'initiated' &&
                    <p>{t('offersLabels:offerCardLabelRedeemedStateConfirmation')}</p>
                }
            </div>
        }

        <div>
            {showRedeemButton &&
                <Button variant="contained" 
                        onClick={ onRedeem }
                        disabled={ preview }
                        fullWidth={ preview }
                        className='fullwidth-card-button'>
                        {t('offersLabels:offerCardButton')}
                </Button>
            }            
        </div>
    </div>
    
  )
};

OfferCardFooter.propTypes = {
    state:PropTypes.string,
    showRedeemButton: PropTypes.bool,
    onRedeem: PropTypes.func,
    preview: PropTypes.bool,
};

export default OfferCardFooter